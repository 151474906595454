import React, { useState } from 'react';

const DynamicUrlInfo = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const dUrl = "{{1}}";

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-md">
            <div className="tw-flex tw-justify-between tw-items-center">
                <h3 className="tw-text-lg tw-font-semibold">There are 2 ways of providing the dynamic URL:</h3>
                <span 
                    onClick={toggleExpand}
                    className="tw-text-blue-600 tw-text-sm tw-font-medium hover:tw-underline focus:tw-outline-none tw-cursor-pointer"
                >
                    {isExpanded ? "Read less" : "Read more"}
                </span>
            </div>
            
            {isExpanded && (
                <div className="tw-mt-4 tw-space-y-4">
                    <div className="tw-flex tw-space-x-3">
                        <div className="tw-flex-shrink-0 tw-w-6 tw-h-6 tw-bg-blue-500 tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center">1</div>
                        <div>
                            <p className="tw-font-medium">Dynamic portion of URL as a variable</p>
                            <p className="tw-text-gray-600">The provided URL should have a variable in place of the dynamic portion of the URL. If your actual URL is <span className="tw-font-semibold">https://www.example.com/order/12345</span> where 12345 is the dynamic part, you should enter the URL as <span className="tw-font-semibold">https://www.example.com/order/{dUrl}</span>.</p>
                            <p className="tw-text-gray-600">You can map {dUrl} to a user trait / event trait which contains the dynamic portion for each customer.</p>
                        </div>
                    </div>
                    <div className="tw-flex tw-space-x-3">
                        <div className="tw-flex-shrink-0 tw-w-6 tw-h-6 tw-bg-blue-500 tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center">2</div>
                        <div>
                            <p className="tw-font-medium">Full URL as a variable</p>
                            <p className="tw-text-gray-600">Provide the dynamic URL as <span className="tw-font-semibold tw-underline">https://goqii.com/cta?redirect={dUrl}</span>.</p>
                            <p className="tw-text-gray-600">You can map {dUrl} to a user trait / event trait, which contains the full URL for each customer.</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DynamicUrlInfo;