import React, { useState,useRef,useEffect, useCallback } from 'react';
import { useInboxContext } from './InboxContext';
import { FiX, FiChevronDown, FiCheck, FiSearch } from 'react-icons/fi';
import { BiCheckDouble, BiCheck  } from "react-icons/bi";
import { ImUserCheck } from "react-icons/im";
import Picker from 'emoji-picker-react';
import { send_message,fetch_chat,check_status } from '../../../services/whatsapp/Service';
import TemplatePopup from '../Inbox/TemplatePopup';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const CustomTooltip = ({ text, visible }) => {
  if (!visible) return null;
  
  return (
    <div 
      className="tw-absolute tw-right-[70%] tw-top-1/2 tw--translate-y-1/2 tw-mr-2 tw-z-[9999] tw-pointer-events-none"
    >
      <div className="tw-bg-[#1279BE] tw-text-white tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-whitespace-nowrap tw-relative">
        {text}
        {/* Arrow pointing right */}
        <div className="tw-absolute tw-right-[-6px] tw-top-1/2 tw--translate-y-1/2 tw-w-0 tw-h-0 tw-border-l-4 tw-border-y-4 tw-border-solid tw-border-y-transparent tw-border-l-[#1279BE]" />
      </div>
    </div>
  );
};


const ChatArea = () => {
  const { initialMessageCount,setInitialMessageCount,selectedContact,resetSelectedContact,showkeyboard,chatResult,loadingMore,setLoadingMore,chatPage,setChatResult,setChatpage,setCurrentlySelectedContact,templateStatus,optOut} = useInboxContext();
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [searchAssignee, setSearchAssignee] = useState('');
  const [activeTab, setActiveTab] = useState('reply');
  const [showTemplatePopup, setShowTemplatePopup] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [noteText, setNoteText] = useState('');
  const [fileType, setFileType] = useState('');
  const [msgType, setMsgType] = useState('');
  const [isScroll, setisScroll] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const previousMessageCountRef = useRef(initialMessageCount);
  const id = selectedContact && selectedContact[0] ? selectedContact[0].userId : null;


  const [textMessage, setTextMessage] = useState('');       // For sending normal text
  const [imageFile, setImageFile] = useState(null);         // Selected image file
  const [imagePreview, setImagePreview] = useState(null);   // Image preview URL
  const [imageNote, setImageNote] = useState('');           // Note for image with text
  const [isPopupOpen, setIsPopupOpen] = useState(false);    // Toggle for image preview popup
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null); // Ref for chat container
  const chatEndRef = useRef(null);
  const previousScrollHeightRef = useRef(0);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const [newMessageCount, setNewMessageCount] = useState(0); // New message count


  const [sendText, setSendText] = useState(false); // New loading state

  const [activeTooltip, setActiveTooltip] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showNewMessageAlert, setShowNewMessageAlert] = useState(false); // Track initial message count

  useEffect(() => {
    // Fetch initial message count if it hasn’t been set yet
    const fetchInitialMessageCount = async () => {
      try {
        if(!id) return
        const response = await check_status({ id });
        if(response.data.code==200){
          const initialCount = response.data.data.msgCount;
          setInitialMessageCount(initialCount);
          previousMessageCountRef.current = initialCount; // Set the ref to this initial value
        }
        
      } catch (error) {
        console.error("Failed to fetch initial message count:", error);
      }
    };

    // Only fetch if initialMessageCount is unset or selected contact changes
    if (initialMessageCount === 0 && id) {
      fetchInitialMessageCount();
    }
  }, [id, initialMessageCount, setInitialMessageCount]);

  useEffect(() => {
    // Check for new messages based on current and previous message count
    const checkForNewMessages = async () => {
      try {
        if(!id) return;
        const response = await check_status({ id });
        if(response.data.code==200){
          const newMessageCount = response.data.data.msgCount;
          console.log('previousMessageCountRef.current',previousMessageCountRef.current)
          if (newMessageCount > previousMessageCountRef.current) {
            console.log('New messages detected!');
            const diff=newMessageCount-previousMessageCountRef.current;
            setNewMessageCount(diff); // Set initial message count
            previousMessageCountRef.current = newMessageCount; // Set the ref to this initial value
            setInitialMessageCount(newMessageCount);
            setShowNewMessageAlert(true)
            setChatpage(1); // Call API on Enter
            setTimeout(() => {
              setChatResult([]);
              getchat(id, 1); // Fetch chat for the new message
              setNewMessageCount(0)
            }, 1000);
  
            setTimeout(() => setShowNewMessageAlert(false), 3000);
          }
        }
        
      } catch (error) {
        console.error("Failed to check for new messages:", error);
      }
    };

    // Set up polling for new messages every 15 seconds
    const interval = setInterval(() => checkForNewMessages(), 15000);
    return () => clearInterval(interval);
  }, [id, setInitialMessageCount]);

   // Create a debounced version of getchat
   const debouncedGetChat = useCallback(
    debounce((id, page) => {
      getchat(id, page);
    }, 500), // 500ms delay
    [] // Empty dependency array since getchat is likely coming from props
  );
  

  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return;
    
    const container = chatContainerRef.current;
    const scrollPosition = Math.round(container.scrollTop);
    const totalHeight = Math.round(container.scrollHeight);
    const visibleHeight = Math.round(container.clientHeight);
    const threshold = 50;
    console.log(chatPage,'chatpage')
    // Check if user is at the top (for loading more messages)
    if (scrollPosition <= 10) {
      previousScrollHeightRef.current = totalHeight;
      if (chatPage > 1) {
        debouncedGetChat(id, chatPage);
      }
      setIsAtTop(true);
    } else {
      setIsAtTop(false);
    }

    // Check if user is near bottom
    const distanceFromBottom = totalHeight - (scrollPosition + visibleHeight);
    const isAtBottom = distanceFromBottom <= threshold;
    
    // Update states based on scroll position
    setIsAtBottom(isAtBottom);
    setShowScrollButton(!isAtBottom);
  }, [chatPage, id]); // Add dependencies as needed

  // Debounced version of the scroll handler itself
  const debouncedHandleScroll = useCallback(
    debounce(() => {
      handleScroll();
    }, 100), // 100ms delay for scroll handling
    [handleScroll]
  );

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', debouncedHandleScroll);
      return () => {
        container.removeEventListener('scroll', debouncedHandleScroll);
        // Clean up any pending debounced calls
        debouncedHandleScroll.cancel?.();
        debouncedGetChat.cancel?.();
      };
    }
  }, [debouncedHandleScroll, debouncedGetChat]);

  // console.log(chatEndRef)

  const scrollToBottom = () => {
    if (!chatEndRef.current || !chatContainerRef.current) return;
    
    const container = chatContainerRef.current;
    const targetPosition = chatEndRef.current.offsetTop;
    const startPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const targetHeight = chatEndRef.current.clientHeight;
    
    // Calculate the exact position to scroll to
    const scrollDistance = targetPosition - startPosition - containerHeight + targetHeight + 32; // adding padding
    
    try {
      container.scrollTo({
        top: container.scrollTop + scrollDistance,
        behavior: 'instant' // Use 'instant' for initial load
      });
    } catch (error) {
      // Fallback for older browsers
      container.scrollTop = container.scrollTop + scrollDistance;
    }
  };



const getchat = async (id,newStartPage) => {
  try {
    const response = await fetch_chat({ id: id,page:newStartPage }); // Pass the current page to the API
    if (response.data.data.chat && response.data.data.chat.length > 0) {
      setChatResult(prevChats => [...response.data.data.chat, ...prevChats]); // Prepend new messages
      setChatpage(newStartPage + 1); // Update the current page
      // setLoadingMore(true);
    } else {
      setLoadingMore(false); // No more data to load
    }

  } catch (error) {
    console.error('Failed to fetch contacts:', error);
  }
};

// useEffect(() => {
//   scrollToBottom();
//   setChatResult([])
//   getchat(id,1);
//   setLoadingMore(true);
  
// }, [id])

useEffect(() => {
  if (!id) return;

  const loadChatAndStatus = async () => {
    try {
      setChatResult([]); // Clear previous chat messages
      setLoadingMore(true); // Set loading state
      await getchat(id, 1); // Load chat messages
      setLoadingMore(false); // Reset loading state
    } catch (error) {
      console.error("Failed to load chat and status:", error);
      setLoadingMore(false);
    }
  };

  loadChatAndStatus();
}, [id]);

// Separate useEffect for scrolling after chat loads
useEffect(() => {
  if (chatResult.length > 0 && !loadingMore) {
    // Small timeout to ensure DOM is updated
    const scrollTimeout = setTimeout(() => {
      scrollToBottom();
    }, 100);
    
    return () => clearTimeout(scrollTimeout);
  }
}, [loadingMore]);

const refreshchat =()=>{
  scrollToBottom();
  setChatResult([])
  setChatpage(1)
  getchat(id,1)
  setLoadingMore(true);
}

// Update scroll position after new messages are loaded
useEffect(() => {

  if (isAtBottom) {
    scrollToBottom();
  } else {
    // Maintain the previous scroll position when loading new data
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
      chatContainerRef.current.scrollHeight - previousScrollHeightRef.current;
    }
  }
}, [chatResult]); // Re-run when messages change

  const handleSendText = async () => {
    if (textMessage.trim()) {
        console.log("Sending text message:", textMessage);
        if (!id) {
          alert("No contact selected to send the message.");
          return;
        }
        setSendText(true);
        
        try {
          const response = await send_message({ message:textMessage,id:id }); // Pass the current page to the API
          console.log(response,'response')
          if(response.data.code==200){
            setChatpage(1);
            setChatResult([])
            getchat(id,1);
            setLoadingMore(true);
            setShowEmojiPicker(false);
            setSendText(false);
          }
          
        } catch (error) {
          console.error('Failed to fetch contacts:', error);
        }
        // Add send logic here
        setTextMessage(''); // Clear text after sending
    } else {
        alert("Message cannot be empty");
    }
};

const onEmojiClick = (emojiObject) => {
  setTextMessage(prevMessage => prevMessage + emojiObject.emoji); // Append emoji to the message
  setShowEmojiPicker(false);
};

useEffect(() => {
  const handleClickOutside = (event) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

const fileclear=()=>{
  setIsPopupOpen(false);
  fileInputRef.current.value = '';
}

// Handle image selection and preview display
const handleImageChange = (e) => {
    const file = e.target.files[0];
    let fileType = '';
    let msgType = '';

    const fileMimeType = file && file.type;

    // Determine file type
    if (fileMimeType.startsWith('image/')) {
        fileType = 'image';
        msgType = 'image';
    } else if (fileMimeType.startsWith('audio/')) {
        fileType = 'audio';
        msgType = 'audio';
    } else if (fileMimeType.startsWith('video/')) {
        fileType = 'video';
        msgType = 'video';
    } else if (fileMimeType === 'application/pdf') {
        fileType = 'pdf';
        msgType = 'document';
    } else if (fileMimeType === 'application/vnd.ms-excel' || fileMimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        fileType = 'excel';
        msgType = 'document';
    } else {
        console.log('Unsupported file type');
        return;
    }
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setFileType(fileType);
            setMsgType(msgType);
            setImageFile(file);
            setImagePreview(reader.result);
            setIsPopupOpen(true); // Open popup for image preview
        };
        reader.readAsDataURL(file);
    }
};

// Send image with accompanying text
const handleSendImageWithText = async() => {
    if (imageFile && imageNote.trim()) {
        console.log("Sending image with text:", { imageFile, imageNote });

        const formData = new FormData();
        formData.append('id', id); // Add contact ID
        formData.append('message', imageNote); // Add the text note
        formData.append('imageFile', imageFile); // Add the image file
        formData.append('fileType', fileType); // Add the image file
        formData.append('msgType', msgType); // Add the image file

        setSendText(true);
        try {
            const response = await send_message(formData, {
              headers: { 'Content-Type': 'multipart/form-data' } // Set header for FormData
            });

            if(response.data.code==200){
              setChatpage(1);
              setChatResult([])
              getchat(id,1);
              setLoadingMore(true);
              setShowEmojiPicker(false);
              setSendText(false);
            }

            if (response.ok) {
                console.log("Image with text sent successfully");
            } else {
                console.error("Failed to send image with text");
            }
        } catch (error) {
            console.error('Error sending image with text:', error);
        }
        // Add send logic here
        setImageFile(null);
        setImagePreview(null);
        setImageNote('');
        setIsPopupOpen(false);
        setFileType('');
        setMsgType('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
    } else {
        alert("Both image and message cannot be empty");
    }
};



  if (!selectedContact) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
        <p className="tw-text-gray-500">Select a member to start chatting</p>
      </div>
    );
  }


  // console.log(chatResult,'chatResult')

  const getMessageStatusIcon = (status) => {
    switch (status?.toLowerCase()) {
      
      case 'sent':
        return <BiCheck className="tw-w-4 tw-h-4 tw-text-gray-500" />;
      case 'delivered':
      case 'unread':
        return <BiCheckDouble className="tw-w-4 tw-h-4 tw-text-gray-500" />;
      case 'read':
        return <BiCheckDouble className="tw-w-4 tw-h-4 tw-text-blue-500" />;
      case 'undelivered':
      case 'failed':
        return <i className="bi bi-info-circle tw-w-4 tw-h-4 tw-text-red-500"></i>;
      case 'queued':
      default:
        return <i className="bi bi-clock-history tw-text-gray-500"></i>;
    }
  };

  const replaceVariables = (content) => {
    if (!content) return '';
    
    return content
      // Handle line breaks first
      .replace(/\\n/g, '<br />')
      
      // Bold: *text* (not preceded or followed by space)
      .replace(/\*([^*]+)\*/g, '<strong>$1</strong>')
      
      // Italic: _text_ (not preceded or followed by space)
      .replace(/(?<!\s)_(.*?)_(?!\s)/g, '<em>$1</em>')
      
      // Strikethrough: ~text~ (not preceded or followed by space)
      .replace(/(?<!\s)~(.*?)~(?!\s)/g, '<del>$1</del>')
      
      // Monospace: ```text``` 
      .replace(/```(.*?)```/g, '<code>$1</code>')
      
      // Single line code: `text`
      .replace(/`(.*?)`/g, '<code>$1</code>')
  
      // URLs: automatically link URLs
      .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">$1</a>')
  
      // WhatsApp style quotes: > at start of line
      .replace(/(^|\n)&gt; ?([^\n]*)/g, '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>')
      
      // Lists: - or • at start of line
      .replace(/(^|\n)[-•] ?([^\n]*)/g, '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>')
      
      // Handle multiple underscores or asterisks in a single word
      .replace(/(\*{2}|_{2})(.*?)\1/g, '<strong>$2</strong>')
      .replace(/(\*{3}|_{3})(.*?)\1/g, '<strong><em>$2</em></strong>')
      
      // Fix any potentially broken HTML from nested formatting
      .replace(/<(\/?)(strong|em|del|code)>/g, '<$1$2>');
  };


  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-bg-gray-100">
      {/* Chat header */}
      <div className="tw-flex-none tw-px-4 tw-py-2 tw-bg-white tw-border-b">
        <div className="tw-flex tw-justify-between tw-items-start">
        <div className="tw-flex tw-items-start tw-flex-col">
        <h1 className='tw-font-bold tw-text-xl'>{selectedContact[0]['memberName']}</h1>
        <h2 className="tw-font-bold tw-text-sm tw-text-gray-500">{selectedContact[0]['mobile']}</h2>
        </div>
       

        <div className="tw-flex">
        {/* <span
          onClick={refreshchat}
          className="tw-mr-3 tw-flex tw-items-center tw-cursor-pointer tw-justify-center tw-w-8 tw-h-8 tw-bg-gray-200 tw-rounded-full hover:tw-bg-gray-300"
        >
          <i className="bi bi-arrow-clockwise"></i>
        </span> */}
         <span 
      onClick={refreshchat} 
      className="tw-inline-flex tw-items-center tw-mt-2 tw-cursor-pointer tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3"
    >
      <i className="bi bi-arrow-clockwise tw-text-lg"></i>
    </span>
          {/* <button onClick={() => {
      resetSelectedContact();
      setCurrentlySelectedContact(null)
       // Assuming this function exists
    }} className="btn btn-primary clear-search-btn">Close chat</button> */}
        </div>
      </div>
      </div>

      {/* Chat messages */}
      {/* <div className='tw-'> */}
      <div 
      onScroll={handleScroll} 
      ref={chatContainerRef}
      className="tw-flex-1 tw-overflow-y-auto tw-p-4 tw-relative tw-bg-gray-100"
      style={{ 
        minHeight: 0, // Important for proper flex behavior
        scrollbarWidth: 'thin',
        scrollbarColor: '#CBD5E0 #F3F4F6'
      }}
       >
  {chatResult.length > 0 && 
    chatResult.map((message, index) => (
      <React.Fragment>
          <div 
          key={message.id} 
          className={`tw-mb-4 tw-gap-2 tw-flex ${message.senderType === "agent" ? "tw-justify-end" : ""}`} 
          // ref={message.lastElement === true ? chatEndRef : null}
          ref={index === chatResult.length - 1 ? chatEndRef : null}
          >
            
            {/* User message */}
            {message.senderType === "user" && (
  <div className='tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-2 tw-max-w-[300px]'>
    <div className="tw-w-8 tw-h-8 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mt-2 tw-flex-shrink-0">
      {message.sender.split(" ")[0].charAt(0).toUpperCase()}
    </div>
    <div className='tw-flex tw-flex-col tw-items-start tw-justify-start'>
      {/* Reply Preview */}
      
      {message.originalId ? (
         <>
         
             {message.headerType.toLowerCase() === "image" && (
           <div className='tw-bg-gray-50 tw-w-full tw-p-3 border tw-border-b-0 tw-rounded-t-lg tw-flex tw-items-center tw-gap-2 hello'>
               <img 
                 src={message.mediaUrl}
                 alt={message.templateName}
                 className="tw-w-full tw-h-full tw-object-cover tw-rounded"
               />
             </div>
             )}
             {/* {message.headerType.toLowerCase() === "text" && (
               <span className="tw-text-sm">{message.replyText}</span>
             )} */}
         
       </>
      ) : (
        <>
        {message.msgType === "image" && (
          <div className="tw-max-w-[300px]">
            <div className={`tw-bg-white tw-rounded-t-lg ${message.originalText ? "border tw-border-b-0" : ""} tw-p-1 tw-shadow-sm`}>
              <img 
                src={message.imgUrl} 
                alt="image" 
                className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg" 
              />
            </div>
          </div>
        )}
        </>
      )}

      {message.originalId ? null : (
        <>
      {message.msgType === "audio" && (
        <div className={`tw-bg-white tw-rounded-t-lg ${message.originalText ? "border" : ""} tw-p-2 tw-shadow-sm tw-max-w-[280px]`}>
          <div className="tw-flex tw-items-center tw-gap-2">
            <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
              <i className="bi bi-mic-fill tw-text-white"></i>
            </div>
            <audio controls className="tw-w-full tw-h-[36px]">
              <source src={message.imgUrl} type="audio/mpeg" />
            </audio>
          </div>
        </div>
      )}
        </>
      )}

{message.originalId ? (
        <>
        {message.headerType.toLowerCase() === "video" && (
          <video className="tw-bg-gray-50 tw-w-full tw-p-3 border tw-border-b-0 tw-rounded-t-lg tw-flex tw-items-center tw-gap-2" controls>
          <source src={message.mediaUrl} type="video/mp4" />
        </video>
        )}
        </>
      ) : (
        <>
      {message.msgType === "video" && (
        <div className="tw-max-w-[300px]">
          <div className={`tw-bg-white ${message.originalText ? "border" : ""} tw-rounded-t-lg tw-p-1 tw-shadow-sm`}>
            <video 
              controls 
              src={message.imgUrl} 
              className="tw-w-full tw-max-h-[280px] tw-rounded-lg"
            />
          </div>
        </div>
      )}
        </>
      )}
      {message.originalId ? (
        <>
        {message.headerType.toLowerCase() === "document" && (
          <div className="tw-bg-gray-50 tw-w-full tw-p-3 border tw-border-b-0 tw-rounded-t-lg tw-flex tw-items-center tw-gap-2">
          <svg className="tw-w-6 tw-h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
          </svg>
          <span className="tw-text-sm">Document attached</span>
        </div>
        )}
        </>
      ) : (
        <>
      {message.msgType === "document" && (
        <div className={`tw-bg-white ${message.originalText ? "border" : ""} tw-rounded-t-lg tw-shadow-sm tw-max-w-[300px] tw-overflow-hidden`}>
          <div className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-gray-50">
            <div className="tw-flex-shrink-0">
              <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
            </div>
            <div className="tw-flex-1 tw-min-w-0">
              <div className="tw-truncate tw-text-sm">Document</div>
              <div className="tw-text-xs tw-text-gray-500">PDF</div>
            </div>
          </div>
          {message.mimeType === "application/pdf" ? (
            <iframe
              src={message.imgUrl}
              title="PDF Document"
              className="tw-w-full tw-h-[200px]"
            />
          ) : (
            <a href={message.imgUrl} target="_blank" rel="noopener noreferrer" 
               className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-gray-50">
              View Document
            </a>
          )}
        </div>
      )}
        </>
      )}

{!/\.(pdf|csv|jpeg|mp4)$/i.test(message.messageText) && message.messageText && (
  <div className="tw-max-w-[300px] tw-w-full">
    <div 
      dangerouslySetInnerHTML={{
        __html: message.originalId ? 
          replaceVariables(message.originalText) : 
          message.messageText.split('').reduce((result, char, index) => {
            if (index > 0 && index % 25 === 0) {
              return result + '\u200B' + char;
            }
            return result + char;
          }, '')
      }} 
      className={`
        tw-bg-white tw-p-2.5 tw-shadow-sm tw-break-words tw-whitespace-pre-wrap
        ${(() => {
          // If this is a reply message
          if (message.originalId) {
            // If there's a header type (image, video, etc)
            if (message.headerType) {
              return 'border tw-border-t-0 tw-border-b-0';
            }
            // If it's just text reply
            return 'border tw-rounded-t-lg';
          }
          
          // If there's a reply text but not a reply message
          if (message.originalText) {
            // If there's media above
            if (message.msgType === 'image' || message.msgType === 'video' || message.msgType === 'document' || message.msgType === 'audio') {
              return 'border tw-border-t-0 tw-rounded-b-lg';
            }
            return 'border tw-rounded-lg';
          }
          
          // Regular message
          return 'tw-rounded-lg';
        })()}
      `}
    />
  </div>
)}
        {message.originalId && (
        <div className="tw-max-w-[300px] tw-w-full tw-mb-1">
          <div className={`tw-bg-gray-200 tw-rounded-b-lg tw-p-2 tw-border-l-4 tw-border-[#03335b]`}>
            <div className="tw-text-xs tw-font-medium tw-text-[#03335b] tw-mb-1">
              {message.originalId ? message.sender : ''}
            </div>
            <div className="">

            {message.msgType === "image" && (
          <div className="tw-max-w-[300px]">
            <div className={`tw-bg-white tw-rounded-t-lg ${message.originalText ? "border tw-border-b-0" : ""} tw-p-1 tw-shadow-sm`}>
              <img 
                src={message.imgUrl} 
                alt="image" 
                className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg" 
              />
            </div>
          </div>
        )}

            {message.msgType === "audio" && (
        <div className={`tw-bg-white tw-rounded-t-lg ${message.originalText ? "border" : ""} tw-p-2 tw-shadow-sm tw-max-w-[280px]`}>
          <div className="tw-flex tw-items-center tw-gap-2">
            <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
              <i className="bi bi-mic-fill tw-text-white"></i>
            </div>
            <audio controls className="tw-w-full tw-h-[36px]">
              <source src={message.imgUrl} type="audio/mpeg" />
            </audio>
          </div>
        </div>
      )}

            {message.msgType === "video" && (
        <div className="tw-max-w-[300px]">
          <div className={`tw-bg-white ${message.originalText ? "border" : ""} tw-rounded-t-lg tw-p-1 tw-shadow-sm`}>
            <video 
              controls 
              src={message.imgUrl} 
              className="tw-w-full tw-max-h-[280px] tw-rounded-lg"
            />
          </div>
        </div>
      )}

            {message.msgType === "document" && (
        <div className={`tw-bg-white ${message.originalText ? "border" : ""} tw-rounded-t-lg tw-shadow-sm tw-max-w-[300px] tw-overflow-hidden`}>
          <div className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-gray-50">
            <div className="tw-flex-shrink-0">
              <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
            </div>
            <div className="tw-flex-1 tw-min-w-0">
              <div className="tw-truncate tw-text-sm">Document</div>
              <div className="tw-text-xs tw-text-gray-500">PDF</div>
            </div>
          </div>
          {message.mimeType === "application/pdf" ? (
            <iframe
              src={message.imgUrl}
              title="PDF Document"
              className="tw-w-full tw-h-[200px]"
            />
          ) : (
            <a href={message.imgUrl} target="_blank" rel="noopener noreferrer" 
               className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-gray-50">
              View Document
            </a>
          )}
        </div>
      )}
              <span dangerouslySetInnerHTML={{__html: message.messageText }} className='tw-text-base tw-text-gray-600 tw-truncate'></span>
            </div>
          </div>
        </div>
      )}
        <div className="tw-text-xs tw-text-gray-500 tw-mt-1 tw-ml-1">{message.logDateTime}</div>
    </div>
  </div>
)}

            {/* <span className='tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-mx-auto'>{message.day ? message.day : '' }</span> */}

            {/* Agent message */}
            {message.senderType === "agent" && (
              <>
                <div className={`tw-max-w-[300px] ${message.messageStatus === "undelivered" || message.messageStatus === "failed" ? "tw-flex tw-items-center tw-gap-2" : ""} `}>
                {(message.messageStatus === "undelivered" || message.messageStatus === "failed") ? (
            <div className="tw-relative">
              <div 
                className="tw-cursor-pointer"
                onMouseEnter={() => setActiveTooltip(message.id)}
                onClick={() => setActiveTooltip(message.id)}
                // onMouseLeave={() => setActiveTooltip(null)}
              >
                {getMessageStatusIcon(message.messageStatus)}
              </div>
              
              {activeTooltip === message.id && (
                <div 
                  className="tw-absolute tw-z-[99999] tw-w-64 tw-h-72 tw-overflow-y-auto tw-right-[19px] tw-bottom-[0px] tw-bg-white tw-rounded-lg tw-border tw-shadow-lg tw-p-4"
                  onMouseEnter={() => setActiveTooltip(message.id)}
                  onMouseLeave={() => setActiveTooltip(null)}
                  style={{ 
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#CBD5E0 #F3F4F6'
                  }}
                >
                  <div className="tw-relative">
                    <p className="tw-text-sm tw-font-bold tw-text-red-600 tw-mb-2">
                      ERROR: {message.errorCode}
                    </p>
                    <p className="tw-text-sm tw-text-gray-600 tw-mb-3">
                      {message.description}
                    </p>
                    <div className="tw-flex tw-flex-col tw-gap-2">
                      <span className='tw-text-sm tw-text-wrap'>
                        <a
                          href={`https://www.twilio.com/docs/api/errors/${message.errorCode}`}
                          className="tw-text-blue-500 hover:tw-underline tw-text-sm tw-mr-1"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          View Error Details
                        </a>
                        about Error code <strong>{message.errorCode}</strong>
                      </span>
                      <span className='tw-text-sm tw-break-words'>
                        Possible Solution: {message.solution}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}
          <div>

                  {message.msgType === "image" && (
                    <div className="tw-max-w-[300px]">
                      <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-p-1 tw-shadow-sm">
                        <img 
                          src={message.imgUrl} 
                          alt="image" 
                          className="tw-w-full tw-max-h-[280px] tw-object-cover tw-rounded-lg" 
                        />
                      </div>
                    </div>
                  )}

                  {message.msgType === "audio" && (
                    <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-p-2 tw-shadow-sm tw-max-w-[280px]">
                      <div className="tw-flex tw-items-center tw-gap-2">
                        <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#03335b] tw-flex tw-items-center tw-justify-center">
                          <i className="bi bi-mic-fill tw-text-white"></i>
                        </div>
                        <audio controls className="tw-w-full tw-h-[36px]">
                          <source src={message.imgUrl} type="audio/mpeg" />
                        </audio>
                      </div>
                    </div>
                  )}

                  {message.msgType === "video" && (
                    <div className="tw-max-w-[300px]">
                      <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-p-1 tw-shadow-sm">
                        <video 
                          controls 
                          src={message.imgUrl} 
                          className="tw-w-full tw-max-h-[280px] tw-rounded-lg" 
                        />
                      </div>
                    </div>
                  )}

                  {message.msgType === "document" && (
                    <div className="tw-bg-[#f0f4ff] tw-rounded-t-lg tw-shadow-sm tw-max-w-[300px] tw-overflow-hidden">
                      <div className="tw-flex tw-items-center tw-gap-3 tw-p-3 tw-bg-sky-100">
                        <div className="tw-flex-shrink-0">
                          <i className="bi bi-file-earmark-pdf tw-text-2xl tw-text-red-500"></i>
                        </div>
                        <div className="tw-flex-1 tw-min-w-0">
                          <div className="tw-truncate tw-text-sm">Document</div>
                          <div className="tw-text-xs tw-text-gray-500">PDF</div>
                        </div>
                      </div>
                      {message.mimeType === "application/pdf" ? (
                        <iframe
                          src={message.imgUrl}
                          title="PDF Document"
                          className="tw-w-full tw-h-[200px]"
                        />
                      ) : (
                        <a href={message.imgUrl} target="_blank" rel="noopener noreferrer" 
                           className="tw-block tw-p-2 tw-text-blue-600 tw-text-sm tw-text-center hover:tw-bg-sky-200">
                          View Document
                        </a>
                      )}
                    </div>
                  )}

                  {!/\.(pdf|csv|jpeg|mp4)$/i.test(message.messageText) && (
                    <div dangerouslySetInnerHTML={{ __html: replaceVariables(message.messageText) }} className="tw-bg-[#f0f4ff] tw-rounded-b-lg tw-p-2.5 tw-shadow-sm tw-break-words tw-whitespace-pre-wrap">
                      
                    </div>
                  )}

                  <div className="tw-flex tw-items-center tw-justify-end tw-gap-1 tw-mt-1 tw-mr-1">
                    <span className="tw-text-xs tw-text-gray-500">  {message.logDateTime}</span>
                    {message.messageStatus === "undelivered" || message.messageStatus === "failed" ? null : (

                    getMessageStatusIcon(message.messageStatus)
                    )}
                  </div>
          </div>
                </div>

                <div className="tw-w-8 tw-h-8 tw-bg-[#03335b] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mt-2 tw-flex-shrink-0">
                  {message.sender.split(" ")[0].charAt(0).toUpperCase()}
                </div>
              </>
            )}
          </div>
        
      </React.Fragment>
    ))
  }
      {showScrollButton && (
    <span
      onClick={scrollToBottom}
      className="tw-sticky tw-float-right tw-bottom-0 tw-right-0 tw-bg-[#1279BE] tw-text-white tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-shadow-lg hover:tw-bg-[#1279BE] tw-transition-all tw-duration-300 tw-z-50"
      aria-label="Scroll to bottom"
    >
      {newMessageCount ? newMessageCount : <i className="bi bi-chevron-down tw-text-lg"></i>}
     
    </span>
  )}
</div>

      {/* </div> */}
      

      {/* Chat input area */}
      <div className="tw-flex-none tw-bg-white tw-border-t">
      <div className="tw-p-2 tw-flex tw-justify-between tw-items-center tw-border-b">
      {/* <div className="tw-flex">
      <button
        className={`tw-px-4 tw-py-2 tw-font-semibold tw-appearance-none tw-transition-all tw-duration-300 ${activeTab === 'reply' ? 'tw-border-b !tw-border-[#03335b]' : ''}`}
        onClick={() => setActiveTab('reply')}
      >
        Reply
      </button>
      <button
        className={`tw-px-4 tw-py-2 tw-font-semibold tw-appearance-none tw-transition-all tw-duration-300 ${activeTab === 'notes' ? 'tw-border-b !tw-border-[#03335b]' : ''}`}
        onClick={() => setActiveTab('notes')}
      >
        Notes
      </button>
    </div> */}
  </div>

  <div className="tw-p-2 tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2">
    {/* {alert(showkeyboard)} */}
      {console.log(optOut,'optOut')}
    {optOut === 'Y' ? (
        <div className="tw-w-full tw-text-gray-600 tw-flex tw-flex-col tw-justify-center tw-text-left tw-bg-gray-100 tw-px-3 tw-py-1 tw-rounded-lg">
        <h5>Opted-out of WhatsApp Communication</h5> 
        <p className='tw-text-sm'>Unable to send WhatsApp message. This member has opted out of WhatsApp messaging. Please use an alternative mobile number to send a WhatsApp message.</p>
      </div>
    ) : (
      !showkeyboard ? (
      <div className="tw-relative tw-w-full">
      <div className="tw-w-full tw-text-gray-600 tw-flex tw-flex-col tw-justify-center tw-text-left tw-bg-gray-100 tw-px-3 tw-py-1 tw-rounded-lg">
        <h5>24-Hour Messaging Limit</h5> 
        <p className='tw-text-sm'>Unable to send WhatsApp messages. This member has already received the maximum allowable messages in the last 24 hours for this number. Please wait 24 hours before attempting to send another message, or use an alternative mobile number to send a WhatsApp message.</p>
      </div>

      {/* Controls container */}
      <div className="tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-w-10 tw-flex tw-flex-col tw-justify-between tw-py-2">
        

        {/* Template */}
        <div className="tw-h-8 tw-flex tw-items-center tw-justify-center">
          {/* <OverlayTrigger placement="left" overlay={<Tooltip>Select Template</Tooltip>}> */}
          {console.log("templateStatus",templateStatus)}
            <span 
            onMouseEnter={() => setActiveTooltip('template')}
            onMouseLeave={() => setActiveTooltip(null)}
            onClick={templateStatus ? () => setShowTemplatePopup(true) : null} // Only enable onClick if templateStatus is true
            className="tw-cursor-pointer tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors tw-relative"
            >
              <i className="bi bi-stack" />
              <CustomTooltip 
              text="Select Template"
              visible={activeTooltip === 'template'}
            />
            </span>
          {/* </OverlayTrigger> */}
        </div>
      </div>
    </div>
    ) : (
      <div className="tw-relative tw-w-full">
      <textarea
        className="tw-border tw-border-gray-300 tw-rounded-2xl tw-w-full tw-pl-2 tw-py-2 tw-pr-12"
        placeholder="Reply here"
        value={textMessage}
        onChange={(e) => setTextMessage(e.target.value)}
        rows={4}
      />

      {/* Controls container */}
      <div className="tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-w-10 tw-flex tw-flex-col tw-justify-between tw-py-2">
        {/* Emoji Picker */}
        <div className="tw-relative tw-h-8 tw-flex tw-items-center tw-justify-center">
          {/* <OverlayTrigger placement="left" overlay={<Tooltip>Emoji</Tooltip>}> */}
            <span
            onMouseEnter={() => setActiveTooltip('emoji')}
            onMouseLeave={() => setActiveTooltip(null)}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="tw-cursor-pointer tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors tw-select-none"
            >
              😊
            </span>
            <CustomTooltip 
              text="Emoji"
              visible={activeTooltip === 'emoji'}
            />
          {/* </OverlayTrigger> */}
          {showEmojiPicker && (
            <div 
              ref={emojiPickerRef} 
              className="tw-absolute tw-right-10 tw-bottom-0 tw-z-50"
            >
              <Picker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>

        {/* Attachment */}
        <div className="tw-h-8 tw-flex tw-items-center tw-justify-center">
          {/* <OverlayTrigger placement="left" overlay={<Tooltip>Attachment</Tooltip>}> */}
            <span 
            onMouseEnter={() => setActiveTooltip('attachment')}
            onMouseLeave={() => setActiveTooltip(null)}
              onClick={() => fileInputRef.current?.click()} 
              className="tw-cursor-pointer tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors"
            >
              <i className="bi bi-paperclip tw-rotate-45" />
              <CustomTooltip 
              text="Attachment"
              visible={activeTooltip === 'attachment'}
            />
              <input
                ref={fileInputRef}
                type="file"
                className="tw-hidden"
                accept="image/*,application/pdf"
                onChange={handleImageChange}
              />
            </span>
          {/* </OverlayTrigger> */}
        </div>

        {/* Template */}
        <div className="tw-h-8 tw-flex tw-items-center tw-justify-center">
          {/* <OverlayTrigger placement="left" overlay={<Tooltip>Select Template</Tooltip>}> */}
            <span
            onMouseEnter={() => setActiveTooltip('template')}
            onMouseLeave={() => setActiveTooltip(null)}
              onClick={() => setShowTemplatePopup(true)}
              className="tw-cursor-pointer tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center hover:tw-text-gray-600 tw-transition-colors tw-relative"
            >
              <i className="bi bi-stack" />
              <CustomTooltip 
              text="Select Template"
              visible={activeTooltip === 'template'}
            />
            </span>
          {/* </OverlayTrigger> */}
        </div>
      </div>
    </div>
    )
    )}
  
    <div className="tw-flex tw-flex-col tw-w-[150px] tw-gap-2 tw-justify-end">
      <button
        className="btn btn-primary clear-search-btn"
        onClick={handleSendText} 
        disabled={sendText || !showkeyboard || optOut==='Y'}
      >
        {sendText ? "Sending..." : "Send"}
      </button>
    </div>

    {/* {showNewMessageAlert   &&  (
        <div
          className="tw-bg-blue-500 tw-text-white tw-p-2 tw-rounded tw-cursor-pointer"
          onClick={scrollToBottom}
        >
          {newMessageCount} new {newMessageCount > 1 ? 'messages' : 'message'}
          </div>
      )} */}
  </div>
</div>

      {showTemplatePopup && (
        <TemplatePopup onClose={() => setShowTemplatePopup(false)} id={id} getchat={getchat}/>
      )}

{isPopupOpen ? (
    <div className="tw-fixed tw-inset-0 tw-bg-black/50 tw-flex tw-items-center tw-justify-center tw-z-[9999] tw-p-4">
      <div className="tw-bg-white tw-rounded-lg tw-overflow-hidden tw-w-full tw-max-w-5xl tw-flex tw-flex-col tw-max-h-[80vh]">
        {/* Header */}
        <div className="tw-flex-none tw-bg-white tw-px-6 tw-py-4 tw-border-b">
          <div className="tw-flex tw-justify-between tw-items-center">
            <h2 className="tw-text-xl tw-font-semibold tw-text-gray-700">Preview</h2>
            <span
              onClick={() => fileclear()}
              className="tw-text-gray-500 tw-font-bold hover:tw-text-gray-700 tw-bg-white tw-text-xl tw-rounded-full tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-transition-colors tw-cursor-pointer"
            >
              ✕
            </span>
          </div>
        </div>

        {/* Content area */}
        <div className="tw-flex-1 tw-overflow-y-auto tw-min-h-0">
          <div className="tw-p-6">
            <ImagePreviewPopup 
              imageSrc={imagePreview} 
              note={imageNote}
              onNoteChange={(e) => setImageNote(e.target.value)}
              onClose={() => setIsPopupOpen(false)} 
              onSend={handleSendImageWithText} 
              fileType={fileType}
              sendText={sendText}
            />
          </div>
        </div>

        {/* Footer */}
        <div className="tw-flex-none tw-bg-gray-50 tw-px-6 tw-py-4 tw-border-t">
          <div className="tw-flex tw-justify-center">
            <span
              disabled={sendText}
              onClick={handleSendImageWithText}
              className="tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-all tw-duration-300 tw-text-white tw-px-8 tw-py-2 tw-rounded disabled:tw-opacity-50 disabled:tw-cursor-not-allowed tw-cursor-pointer"
            >
              {sendText ? (
                <span className="tw-flex tw-items-center tw-gap-2">
                  <span className="tw-w-4 tw-h-4 tw-border-2 tw-border-white tw-border-t-transparent tw-rounded-full tw-animate-spin"/>
                  Sending...
                </span>
              ) : (
                "Confirm & Send"
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : null}
    </div>
  );
};


const ImagePreviewPopup = ({ imageSrc, fileType, note, onNoteChange }) => {
  return (
    <div className="tw-flex tw-flex-col tw-space-y-6">
      {/* File preview based on file type */}
      <div className="tw-flex tw-justify-center tw-bg-gray-50 tw-rounded-lg tw-p-4">
        {fileType === 'image' ? (
          <img 
            src={imageSrc} 
            alt="Selected file" 
            className="tw-max-h-[40vh] tw-object-contain tw-w-auto tw-rounded" 
          />
        ) : fileType === 'pdf' ? (
          <embed 
            src={imageSrc} 
            type="application/pdf" 
            className="tw-w-full tw-h-[40vh] tw-rounded" 
          />
        ) : fileType === 'audio' ? (
          <div className="tw-w-full tw-max-w-md tw-p-4 tw-bg-white tw-rounded-lg tw-shadow">
            <audio controls className="tw-w-full">
              <source src={imageSrc} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        ) : fileType === 'video' ? (
          <video 
            controls 
            className="tw-max-h-[40vh] tw-w-auto tw-rounded"
          >
            <source src={imageSrc} type="video/mp4" />
            Your browser does not support the video element.
          </video>
        ) : fileType === 'excel' ? (
          <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow tw-text-center">
            <i className="bi bi-file-earmark-spreadsheet tw-text-4xl tw-text-green-600 tw-mb-2"></i>
            <p className="tw-text-gray-700">Excel file selected. No preview available.</p>
          </div>
        ) : null}
      </div>

      {/* Text area for adding a note */}
      <div className="tw-bg-white tw-rounded-lg">
        <textarea
          value={note}
          onChange={onNoteChange}
          placeholder="Add a message to accompany the file..."
          className="tw-w-full tw-p-4 tw-border tw-rounded-lg tw-h-24 tw-resize-none focus:tw-ring-2 focus:tw-ring-[#03335b] focus:tw-border-transparent"
        />
      </div>
    </div>
  );
};




export default ChatArea;