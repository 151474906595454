import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from "moment";

const CalendarDatePickerComponent = ({ startDate, endDate, onDateChange, index, ytdStart, ytdEnd }) => {
  const [label, setLabel] = useState('');

  const handleCallback = (start, end) => {
    setLabel(`${start.format('D MMM, YYYY')} - ${end.format('D MMM, YYYY')}`);
    if (onDateChange) {
      onDateChange({ start, end, index });
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setLabel(`${startDate.format('D MMM, YYYY')} - ${endDate.format('D MMM, YYYY')}`);
    }
  }, [startDate, endDate]);

  const ranges = {
    'Today': [moment().toDate(), moment().toDate()],
    'Yesterday': [
      moment().subtract(1, 'days').toDate(),
      moment().subtract(1, 'days').toDate(),
    ],
    'Last 7 Days': [
      moment().subtract(6, 'days').toDate(),
      moment().toDate(),
    ],
    'Last 30 Days': [
      moment().subtract(29, 'days').toDate(),
      moment().toDate(),
    ],
    'This Month': [
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate(),
    ],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month').toDate(),
      moment().subtract(1, 'month').endOf('month').toDate(),
    ],
  };

  // Only add PSTD range if ytdStart and ytdEnd are provided
  if (ytdStart && ytdEnd) {
    ranges['PSTD'] = [
      moment(ytdStart).toDate(),
      moment(ytdEnd).toDate(),
    ];
  }

  return (
    <DateRangePicker
      initialSettings={{
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        locale: {
          format: 'D MMM, YYYY',
        },
        ranges: ranges,
      }}
      onCallback={handleCallback}
    >
      <div
        id="reportrange"
        style={{
          background: '#fff',
          cursor: 'pointer',
          padding: '10px 14px',
          border: '1px solid #DCDCDC',
          width: '100%',
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <i className="bi bi-calendar4-week"></i>&nbsp;
        <span>{label}</span> <i className="fa fa-caret-down"></i>
      </div>
    </DateRangePicker>
  );
};

const AdvancedFilter = ({
  initialQueries = [],
  fields = [],
  onFilter,
  maxQueries = 4,
  showDownloadSection = false,
  showRemoveSection = false,
  onRemove,
  customClassName = "",
  ytdStart = null,
  ytdEnd = null,
}) => {
  const [queries, setQueries] = useState(initialQueries);
  const [showAndOr, setShowAndOr] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [remarks, setRemarks] = useState('');
  const [remarkError, setRemarkError] = useState('');
  const [showRemoveBlock, setShowRemoveBlock] = useState(false);

  const allOperators = {
    text: [
      { value: "=", display: "Equals" },
      { value: "like", display: "Contains" },
    ],
    select: [
      { value: "=", display: "Equals" },
      { value: "like", display: "Contains" },
    ],
    date: [
      { value: "between", display: "Between" },
      { value: "=", display: "Equals" },
      { value: ">", display: "Greater than" },
      { value: "<", display: "Less than" },
    ],
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = ({ start, end, index }) => {
    const newQueries = [...queries];
    newQueries[index]['value'] = formatDate(start) + "," + formatDate(end);
    newQueries[index]['startDate'] = start;
    newQueries[index]['endDate'] = end;
    setQueries(newQueries);
  };

  const handleDateChangeSingle = (start, index) => {
    const newQueries = [...queries];
    newQueries[index]['value'] = formatDate(moment(start));
    newQueries[index]['startDate'] = moment(start);
    newQueries[index]['endDate'] = moment(start);
    setQueries(newQueries);
  };

  const handleAddQuery = (condition) => {
    setShowRemoveBlock(false);
    const newQueries = [...queries];
    newQueries[newQueries.length - 1].condition = condition;
    
    const selectedFields = newQueries.map(query => query.field);
    const remainingFieldsCount = fields.filter(field => !selectedFields.includes(field.value)).length;
    
    if (remainingFieldsCount <= 1 || newQueries.length >= maxQueries - 1) {
      setShowAndOr(false);
    }

    const defaultField = fields[0];
    newQueries.push({
      field: defaultField.value,
      operator: allOperators[defaultField.type][0].value,
      value: defaultField.type === 'date' ? 
        `${formatDate(moment().subtract(6, "days"))},${formatDate(moment())}` : "",
      condition: null,
      startDate: moment().subtract(6, "days"),
      endDate: moment(),
      type: defaultField.type,
    });

    setQueries(newQueries);
  };

  const handleRemoveQuery = (index) => {
    const newQueries = queries.filter((_, i) => i !== index);
    setQueries(newQueries);
    setShowAndOr(true);
  };

  const handleQueryChange = (index, key, value, fieldType) => {
    setErrorMsg('');
    const newQueries = [...queries];
    
    if (key === "field") {
      if (fieldType === "date") {
        newQueries[index].value = `${formatDate(moment().subtract(6, "days"))},${formatDate(moment())}`;
        newQueries[index].startDate = moment().subtract(6, "days");
        newQueries[index].endDate = moment();
      } else {
        newQueries[index].value = "";
      }
      
      const validOperators = allOperators[fieldType];
      newQueries[index].operator = validOperators[0].value;
      newQueries[index].type = fieldType;
    }

    newQueries[index][key] = value;
    setQueries(newQueries);
  };

  const handleMultiSelectChange = (index, selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value).join(",") : "";
    handleQueryChange(index, "value", selectedValues, "select");
  };

  const renderInput = (query, index) => {
    switch (query.type) {
      case "date":
        return query.operator === "between" ? (
          <CalendarDatePickerComponent
            startDate={query.startDate}
            endDate={query.endDate}
            onDateChange={handleDateChange}
            index={index}
            ytdStart={ytdStart}
            ytdEnd={ytdEnd}
          />
        ) : (
          <div className="date-picker-wrapper" style={{ position: 'relative' }}>
            <DatePicker
              selected={query.endDate.toDate()}
              onChange={(date) => handleDateChangeSingle(date, index)}
              dateFormat="dd MMM, yyyy"
              className="form-control input-field-ui"
              isClearable={false}
            />
            <i className='bx bx-calendar' style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              pointerEvents: 'none',
              fontSize: '20px',
              color: '#666'
            }}></i>
          </div>
        );
      case "select":
        const options = fields.find(f => f.value === query.field)?.options || [];
        const selectedOptions = options.filter(option => query.value.includes(option.value));
        return (
          <MultiSelect
            options={options}
            value={selectedOptions}
            onChange={(selected) => handleMultiSelectChange(index, selected)}
            labelledBy="Select"
            hasSelectAll={true}
            className="form-select select-input-ui select-input-ui-select"
          />
        );
      default:
        return (
          <input
            type="text"
            value={query.value}
            className="form-control input-field-ui"
            onChange={(e) => handleQueryChange(index, "value", e.target.value, "text")}
          />
        );
    }
  };

  const handleFilter = () => {
    setShowRemoveBlock(true);
    onFilter(queries);
  };

  const handleRemove = () => {
    if (!remarks) {
      setRemarkError("Enter Remarks");
      return;
    }
    onRemove(remarks);
  };

  return (
    <div className={`advanced-filter ${customClassName}`}>
      {queries.map((query, index) => (

<div key={index} className="row mb-3 tw-items-start">
  {/* Field Select - Fixed width */}
  <div className="tw-w-[32%] tw-pr-2">
    <select
      value={query.field}
      className="form-select input-field-ui tw-w-full"
      onChange={(e) => {
        const selectedField = fields.find(field => field.value === e.target.value);
        handleQueryChange(index, "field", e.target.value, selectedField.type);
      }}
    >
      {fields.map((field) => (
        <option key={field.value} value={field.value}>
          {field.display}
        </option>
      ))}
    </select>
  </div>

  {/* Operator Select - Fixed width */}
  <div className="tw-w-[32%] tw-pr-2">
    <select
      value={query.operator}
      className="form-select input-field-ui tw-w-full"
      onChange={(e) => handleQueryChange(index, "operator", e.target.value, query.type)}
    >
      {allOperators[query.type]?.map((operator, i) => (
        <option key={i} value={operator.value}>
          {operator.display}
        </option>
      ))}
    </select>
  </div>

  {/* Value Input - Fixed width */}
  <div className="tw-w-[32%] tw-pr-2">
    {renderInput(query, index)}
  </div>

  {/* Delete Button - Fixed width */}
  {queries.length > 1 && (
    <div className={`tw-w-[4%] tw-flex tw-items-start tw-pt-2.5 ${index === 0 ? "tw-invisible" : ""}`}>
      <span className="tw-cursor-pointer" onClick={() => handleRemoveQuery(index)}>
        <i className="bi bi-trash tw-text-orange-600 hover:tw-text-[#215a87]"></i>
      </span>
    </div>
  )}

  {index < queries.length - 1 && query.condition && (
    <div className="col-12 mt-3 text-center">
      <strong>{query.condition}</strong>
    </div>
  )}

  {index === queries.length - 1 && showAndOr && index < maxQueries - 1 && (
    <div className="col-12 mt-3">
      <button className="btn btn-primary back-btn me-2" onClick={() => handleAddQuery("AND")}>AND</button>
      <button className="btn btn-primary back-btn" onClick={() => handleAddQuery("OR")}>OR</button>
    </div>
  )}
</div>
      ))}

      {errorMsg && <div className="text-danger mb-3">{errorMsg}</div>}

      <div className="mt-3">
        <button className="btn btn-success uplaod-file-btn" onClick={handleFilter}>
          Filter
        </button>
      </div>

      {showRemoveSection && showRemoveBlock && (
        <div className="mt-3">
          <textarea
            rows="3"
            className="form-control input-field-ui"
            placeholder="Remarks *"
            value={remarks}
            onChange={(e) => {
              setRemarkError("");
              setRemarks(e.target.value);
            }}
          />
          {remarkError && <div className="text-danger">{remarkError}</div>}
          <button className="btn btn-success uplaod-file-btn mt-3" onClick={handleRemove}>
            Remove Members
          </button>
        </div>
      )}
    </div>
  );
};

export default AdvancedFilter;