import React, { useState, useEffect, memo } from 'react';
import { FaChevronLeft, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ChooseAudience from './CreateCampaignComponents/ChooseAudience';
import CreateMessage from './CreateCampaignComponents/CreateMessage';
import SetNextSteps from './CreateCampaignComponents/SetNextSteps';
import ScheduleMessage from './CreateCampaignComponents/ScheduleMessage';
import SetMessageLive from './CreateCampaignComponents/SetMessageLive';
import { fetch_tag_list, whatsappCampaignList } from '../../services/mtp/Service';
 

const CreateCampaign = () => {
  const [campaignName, setCampaignName] = useState('');
  const [activeStage, setActiveStage] = useState(0);
  const [mounted, setMounted] = useState(false);
  const navigate = useNavigate();

  //new state added
  const [optionTags, setOptionTags] = useState([]);
  const [whatsTemplateData, setWhatsTemplateData] = useState([]);

  const [selectedFilteredList, setSelectedFilteredList] = useState([]);
  const [totalCountUserList, setTotalCountUserList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [scheduling, setScheduling] = useState('inprogress');
  const [dateTime, setDateTime] = useState(new Date());
  const [memberFilterSelectQuery, setMemberFilterSelectQuery] = useState('');
  const [filterQueries, setFilterQueries] = useState([]);
  const [optOutNCount, setOptOutNCount] = useState('');
  const [optOutYCount, setOptOutYCount] = useState('');


  // const handleDateTimeChange = (date) => {
  //   setDateTime(date);
  // };

 
  

  useEffect(() => {
    fetchAllTags();
    setMounted(true);
    fetchWhatsAppList();
  }, []);

  const fetchAllTags = async () =>{
    try{
      let row = await fetch_tag_list();  
      const data = row.data.data?.map((t) => ({
        label: t.tagName,
        value: t.tagId
      }));
      setOptionTags(data);    
    }catch(error){
      console.log(error);
      
    }
  }

  const fetchWhatsAppList = async () =>{
    try{
      let response = await whatsappCampaignList(); 
      if(response.data.code == 200){  
        setWhatsTemplateData(response.data.data.templateData)
      }else{
        console.log(response.data.data.message);
      }
    }catch(error){
      console.log(error);
    }
  }

  


  const stages = [
    { title: 'Choose Members', content: <ChooseAudience optionTags= {optionTags} selectedFilteredList={selectedFilteredList} setSelectedFilteredList={setSelectedFilteredList} totalCountUserList={totalCountUserList} setTotalCountUserList = {setTotalCountUserList} memberFilterSelectQuery={memberFilterSelectQuery}  setMemberFilterSelectQuery={setMemberFilterSelectQuery} filterQueries={filterQueries} setFilterQueries={setFilterQueries} optOutNCount={optOutNCount} setOptOutNCount={setOptOutNCount} optOutYCount={optOutYCount} setOptOutYCount={setOptOutYCount}/> },
    { title: 'Create your message', content: <CreateMessage whatsTemplateData = {whatsTemplateData} setSelectedTemplate={setSelectedTemplate} selectedTemplate={selectedTemplate} /> },
    // { title: 'Set next steps after customer replies to the message (Recommended) 💎', content: <SetNextSteps /> },
    { title: 'Schedule your message', content: <ScheduleMessage setScheduling={setScheduling} scheduling={scheduling} setDateTime={setDateTime} dateTime={dateTime} /> },
    { title: 'Set your campaign live', content: <SetMessageLive campaignName={campaignName} setCampaignName={setCampaignName} scheduling={scheduling} dateTime={dateTime} selectedTemplate= {selectedTemplate} setSelectedTemplate={setSelectedTemplate} selectedFilteredList={selectedFilteredList} setSelectedFilteredList={setSelectedFilteredList} totalCountUserList={totalCountUserList} setTotalCountUserList = {setTotalCountUserList} memberFilterSelectQuery={memberFilterSelectQuery} setMemberFilterSelectQuery={setMemberFilterSelectQuery} filterQueries={filterQueries} optOutNCount={optOutNCount} setOptOutNCount={setOptOutNCount} optOutYCount={optOutYCount} setOptOutYCount={setOptOutYCount} /> },
  ];

  const handleStageClick = (index) => {
    setActiveStage(index === activeStage ? -1 : index);
  };

  const handleDone = () => {
    if (activeStage < stages.length - 1) {
      setActiveStage(activeStage + 1);
    }
  };

  return (
    <div className={` tw-mb-8  tw-p-4 tw-m-4 tw-rounded-xl tw-transition-opacity tw-duration-500 ${mounted ? 'tw-opacity-100' : 'tw-opacity-0'}`}>
      <style jsx>{`
        @keyframes slideDown {
          from { transform: translateY(-20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        .animate-slide-down {
          animation: slideDown 0.5s ease-out forwards;
        }
        .animate-fade-in {
          animation: fadeIn 0.5s ease-out forwards;
        }
      `}</style>
      <div className="tw-flex tw-items-center tw-gap-2 tw-mb-6 animate-slide-down">
        
        <button
                className="btn btn-primary back-btn mb-0"
                onClick={() => navigate('/notification-dashboard')} 
              >
                <i className="bi bi-chevron-left me-1"></i> Back
              </button>
        <h1 className="tw-text-2xl md:tw-text-2xl tw-font-bold tw-text-gray-500 tw-mt-2">Create WhatsApp Campaign</h1>
      </div>
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-mb-8 animate-slide-down" style={{animationDelay: '0.2s'}}>
        <input
          type="text"
          placeholder="Enter Campaign Name"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          className="form-control input-field-ui"
        />
        {/* <span className="add-new-program-btn tw-cursor-pointer">Draft</span> */}
      </div>
      {stages.map((stage, index) => (
        <div 
          key={index}
          className="tw-mb-6"
          style={{animationDelay: `${0.1 * (index + 1)}s`}}
        >
          <div
            className={`tw-flex tw-items-center tw-justify-between tw-p-4 tw-rounded-t-lg tw-cursor-pointer tw-transition-all tw-duration-300 tw-relative ${
              activeStage === index ? 'tw-bg-[#03335b] tw-text-white' : 'tw-bg-white tw-border tw-border-sky-300 hover:tw-bg-[#03335b] hover:tw-text-white'
            }`}
            onClick={() => handleStageClick(index)}
          >
            <div className="tw-flex tw-items-center">
              <span className={`tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center ${activeStage === index ? "tw-bg-white tw-text-[#03335b]" : "tw-bg-[#03335b] tw-text-white"} tw-rounded-full tw-mr-4 tw-font-bold`}>
                {index + 1}
              </span>
              <span className="tw-text-lg">{stage.title}</span>
            </div>
            {/* <div className="tw-flex tw-items-center">
              {activeStage === index && (
                <button
                  className="tw-bg-white tw-text-gray-600 hover:tw-text-white tw-border tw-border-white tw-px-6 tw-py-2 tw-rounded-full tw-mr-4 tw-font-semibold hover:tw-bg-[#03335b] tw-transition-all tw-duration-300"
                  onClick={handleDone}
                >
                  Done
                </button>
              )}
              <FaChevronDown className={`tw-transition-transform tw-duration-300 ${activeStage === index ? 'tw-rotate-180' : ''}`} />
            </div> */}
          </div>
          {activeStage === index && (
            <div className="tw-border tw-border-t-0 tw-border-[#03335b] tw-rounded-b-lg tw-p-4 tw-bg-white tw-shadow-md tw-relative">
              {stage.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CreateCampaign;