import React from 'react';
import './VariableMappingRow.css';

const VariableMappingRow = ({ valueOptions, variableMappings, setVariableMappings, variableCount }) => {
console.log("variableMappings",variableMappings)
  // Function to handle value changes
  const handleValueChange = (index, newValue) => {
    const updatedMappings = [...variableMappings];
    updatedMappings[index] = { ...updatedMappings[index], value: newValue, variable: index + 1 };
    setVariableMappings(updatedMappings);
  };

  // Function to handle fallback changes
  const handleFallbackChange = (index, newFallback) => {
    const updatedMappings = [...variableMappings];
    updatedMappings[index] = { ...updatedMappings[index], fallback: newFallback , variable: index + 1};
    setVariableMappings(updatedMappings);
  };

  return (
    <div>
      {/* Add a heading */}
      <div className="variable-mapping-row d-flex justify-content-between align-items-center mb-1 mt-1">
        <div className="col-1 form-label"></div>
        <div className="col-2 form-label">Variable</div>
        <div className="col-5 form-label">Value</div>
        <div className="col-3 form-label d-flex align-items-center">
          Fallback/Constant Value 
          {/* <i class="fa fa-info-circle" aria-hidden="true"></i> */}
          </div>
      </div>
      
      {/* Variable Table */}
      {Array.from({ length: variableCount }).map((_, index) => (
        <div key={index} className="variable-mapping-row d-flex justify-content-between align-items-center mb-3">
          
          <div className="col-1 form-label">Body</div>

          {/* Variable */}
          <div className="col-2">
            <div className="input-group">
              <input 
                type="text" 
                className="form-control form-control-sm" 
                value={`{{${index + 1}}}`} 
                readOnly 
              />
            </div>
          </div>

          {/* Value */}
          <div className="col-5">
            <div className="input-group">
              <select
                className="form-select"
                value={variableMappings[index]?.value}
                onChange={(e) => handleValueChange(index, e.target.value)}
                disabled
              >
                <option value="">Choose dynamic/constant value</option>
                {valueOptions?.map((option) => (
                  <option key={option.contentName} value={option.contentName}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Fallback/Constant Value */}
          <div className="col-3">
            <div className="input-group">
              <input 
                type="text" 
                className="form-control form-control-sm" 
                value={variableMappings[index]?.fallback || ''}
                onChange={(e) => handleFallbackChange(index, e.target.value)}
                readOnly
              />
            </div>
            
          </div>

        </div>
      ))}
    </div>
  );
};

export default VariableMappingRow;
