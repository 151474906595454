import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './MemberServices.css';
import { fetchCallsHistory, updateTeleHealthService } from "../../../../services/cn/Service";
import { encryptDataEdge, ModalBoxButton } from '../../../utils/CommonLibrary';


const MemberCallHistory = forwardRef((props, ref) => {
    const qsParams = useParams();
    const userId = qsParams.memberId;

    const memberBasicInfo = props.memberBasicInfo ? props.memberBasicInfo : {};

    const [callsHistory, setCallsHistory] = useState([]);
    const [teleHealthHistory, setTeleHealthHistory] = useState([]);
    const [error, setError] = useState("");
    const [showModalIframe, setShowModalIframe] = useState(false);
    const [modalBodyIframe, setModalBodyIframe] = useState(false);

    useEffect(() => {
        getCallsHistory();
    }, [userId])

    useImperativeHandle(ref, () => ({
        getCallsHistory
    }));

    const getCallsHistory = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchCallsHistory(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setCallsHistory(response.data.data.logs);
                    setTeleHealthHistory(response.data.data.teleService);
                    
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const cancelService = async (bookingId,callId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await updateTeleHealthService('cancel',bookingId,callId,bookingId,bookingId, ct)
            .then((response) => {
                if (response.data.data.bookingData.status == "0") {
                    props.callCallsHistory();
                } else {
                    setError(response.data.data.bookingData.bookingStatus);
                    setTimeout(() => setError(false), 2000);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const openMemberDetailsIframe = (membershipNo,isAramcoApi,appSourceApi,iframeUserApi) => {

        const memberNo = encryptDataEdge(membershipNo);
        const isAramco = encryptDataEdge(isAramcoApi);
        const appSource = encryptDataEdge(appSourceApi);
        const iframeUser = encryptDataEdge(iframeUserApi);
        const frameUrl = `https://careconnectuat.bupa.com.sa/#/support-medical/main-member-medical-profile;memberNo=${memberNo};isAramco=${isAramco};appSource=${appSource};user=${iframeUser}`;
        
        
        setShowModalIframe(true);
        setModalBodyIframe( <div className="iframe-container">
                                <iframe
                                    src={frameUrl}
                                    title="Member Details"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{ height:'75vh',width:'100%' }}
                                />
                            </div>
                        );
    }

    const handleCloseNew = () =>{
        setShowModalIframe(false);
    }


    return (
        <>
            {
                callsHistory &&
                <div className='col-md-12 mt-3'>
                    <div className='card card-task'>
                        <div className='card-body'>
                            <div className='card-text'>
                                <div className='row align-items-center' >
                                    <div className='col'>
                                        <h5 className='card-title'>Calls History</h5>
                                        <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX:"hidden" }}>
                                            <table className="table table-striped table-bordered mb-0">
                                                <thead style={{ position: "sticky", top: "0" }}>
                                                    <tr>
                                                        <th scope="col">Date</th>
                                                        <th scope="col" style={{ width: "15%" }}>Call Type</th>
                                                        <th scope="col" style={{ width: "15%" }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        callsHistory.map((item, index) => {
                                                            return <>
                                                                <tr style={{ verticalAlign: 'middle' }}>
                                                                    <td className='bg-td-green'>{item.date}</td>
                                                                    <td className='bg-td-green'>{item.callType}</td>
                                                                    <td className='bg-td-green'>{item.callCurrentStatus}</td>
                                                                </tr>
                                                            </>
                                                        })  
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            
            {
                teleHealthHistory &&
                <div className='col-md-12 mt-3'>
                    <div className='card card-task'>
                        <div className='card-body'>
                            <div className='card-text'>
                                <div className='row align-items-center' >
                                    <div className='col'>
                                        <h5 className='card-title'>TeleHealth Consultation</h5>
                                        <div className='col-12'>
                                            <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                                        </div>
                                        <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX:"hidden" }}>
                                            <table className="table table-striped table-bordered mb-0">
                                                <thead style={{ position: "sticky", top: "0" }}>
                                                    <tr>
                                                        <th scope="col">Date</th>
                                                        <th scope="col" style={{ width: "15%" }}>Status</th>
                                                        <th scope="col" style={{ width: "15%" }}>Action</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        teleHealthHistory.map((item, index) => {
                                                            return <>
                                                                <tr style={{ verticalAlign: 'middle' }} key={index}>
                                                                    <td className='bg-td-green'>{item.date}</td>
                                                                    <td className='bg-td-green'>{item.callCurrentStatus}</td>
                                                                    <td className={`bg-td-green ${item.showAction == "1" ? "d-flex" : ""}`}>
                                                                        <>
                                                                            {(() => {
                                                                                if (item.showAction == "1") {
                                                                                    return (
                                                                                        <>
                                                                                            {item.showPopup == true && <button className="btn homeCareR-btn me-2" onClick={(e) => props.handleShowUploadTeleHealthReschedule(item.bookingId,item.callId)}>Upload</button> }
                                                                                            <button className="btn homeCareR-btn me-2" onClick={(e) => props.handleShowTeleHealthReschedule(item.bookingId,item.callId,item.doctorId)}>Reschedule</button>
                                                                                            <button className="btn homeCareR-btn" onClick={(e) => cancelService(item.bookingId,item.callId)}>Cancel</button>
                                                                                        </>
                                                                                    )
                                                                                } else if (item.showAction == "2") {
                                                                                    return (
                                                                                        <button className="btn homeCareR-btn me-2" onClick={(e) => openMemberDetailsIframe(item.memberNo,item.isAramco,item.appSource,item.iframeUser)} >View Details</button>
                                                                                    )
                                                                                } else {
                                                                                    return (<></>)
                                                                                }
                                                                            })()}
                                                                        </>
                                                                    </td>
                                                                </tr>   
                                                            </>
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
{showModalIframe ?<ModalBoxButton handleClose={handleCloseNew} showModaln={showModalIframe} titlen="Member Details" bodyn={modalBodyIframe} type="modal-fullscreen"  button={undefined}  hideButton={handleCloseNew}/>:null}
        </>
    )
});

export default MemberCallHistory