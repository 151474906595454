import React, { useState } from 'react';
import ReactDOM from 'react-dom';

// const templates = [
//   { 
//     name: 'coach_message_1', 
//     body: 'Hi {{1}}, I am your coach {{2}} we will be doing your lifestyle changes on here. Please let me know if you have any diet restrictions or allergies.',
//     type: 'text'
//   },
//   { 
//     name: 'coach_message_2_ut', 
//     body: 'Good morning, Ready to make some lifestyle changes?',
//     type: 'text'
//   },
//   { 
//     name: 'live_welcome_1_rev', 
//     body: "Hello 👋\n\nYou're on the verge of a significant health breakthrough!\n\nWith GOQii, you're tapping into a comprehensive health platform that will support you every step of the way on your {{1}} journey. 🌟\n\nImagine easy access to personalised health insights, coaching & a supportive community—all within the GOQii app! This journey is about more than just managing diabetes; it's about embracing a vibrant, healthier life. 💪 🌿\n\nReady to start this new chapter together? Let's do this! 🚀",
//     footer: '#BETHEFORCE',
//     buttons: [{ text: 'Download GOQii', url: 'https://link.interakt.ai/api/wa/{{1}}' }],
//     type: 'text'
//   }
// ];

const TemplateItem = ({ template, onSelect, isSelected }) => (
  <div 
    className={`tw-p-2 tw-border tw-border-gray-300 tw-rounded tw-mb-2 tw-cursor-pointer hover:tw-bg-gray-100 ${isSelected ? 'tw-bg-blue-100' : ''}`}
    onClick={() => onSelect(template)}
  >
    {template.templateName}
  </div>
);

const TemplateMockup = ({ template, variables }) => {
  // Function to replace variables in content
  const replaceVariables = (content) => {
    if (!content) return '';
    let replacedContent = content;
    Object.entries(variables || {}).forEach(([key, value]) => {
      const regex = new RegExp(`{{${key}}}`, 'g');
      replacedContent = replacedContent.replace(regex, value || `{{${key}}}`);
    });
    return replacedContent;
  };

  return (
    <div className="tw-w-full md:tw-w-[360px] tw-mx-auto">
      <div className="tw-relative tw-h-[600px]">
        <div className="tw-w-full tw-h-full tw-bg-[url('https://appcdn.goqii.com/storeimg/49871_1729598479.png')] tw-bg-contain tw-bg-no-repeat tw-bg-center"></div>

        <div className="tw-absolute tw-top-[85px] tw-left-[25px] tw-right-[25px] tw-bottom-[85px] tw-overflow-y-auto [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
          <div className="tw-text-xs tw-text-gray-700 tw-bg-white tw-rounded-lg tw-px-2 tw-w-1/3 tw-text-center tw-mx-auto tw-mb-1 tw-font-semibold">
            Today
          </div>
          
          <div className="tw-bg-white tw-rounded-lg tw-p-3 tw-shadow-sm">
            <div className="tw-text-gray-600 tw-text-sm tw-mb-3">
              {template?.mediaUrl && (
                <div className="tw-mb-2">
                  {template.type === "Image" ? (
                    <img 
                      src={template.mediaUrl}
                      alt={template.name}
                      className="tw-w-full tw-h-full tw-object-cover"
                    />
                  ) : template.type === "Video" ? (
                    <video className="tw-w-full tw-h-full" controls>
                      <source src={template.mediaUrl} type="video/mp4" />
                    </video>
                  ) : template.type === "Document" ? (
                    <embed
                      src={template.mediaUrl}
                      type="application/pdf"
                      className="tw-w-full tw-h-full"
                    />
                  ) : null}
                </div>
              )}
              
              <p className="tw-text-wrap" dangerouslySetInnerHTML={{ __html: replaceVariables(template.body) }}></p>
            </div>

            {template.footer && (
              <p className="tw-text-sm tw-text-gray-500 tw-mb-2">{template.footer}</p>
            )}

            {template.buttons && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                {template.buttons.map((button, index) => (
                  <button
                    key={index}
                    className="tw-text-sm tw-py-1 tw-px-3 tw-rounded tw-text-center tw-border-0 tw-bg-blue-500 tw-text-white"
                  >
                    {replaceVariables(button.text)}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const VariableInputs = ({ template, variables, onVariableChange }) => {
  if (!template) return null;

  const extractVariables = (content) => {
    const matches = new Set();
    if (content) {
      const bodyMatches = content.match(/{{(\d+)}}/g) || [];
      bodyMatches.forEach(match => {
        matches.add(match.replace(/[{}]/g, ""));
      });
    }
    return Array.from(matches).sort((a, b) => Number(a) - Number(b));
  };

  const vars = extractVariables(template.body);
  if (vars.length === 0) return null;

  return (
    <div className="tw-mb-6 tw-bg-gray-50 tw-p-4 tw-rounded-lg">
      {/* <h5 className="tw-font-bold tw-mb-4">Template Variables:</h5> */}
      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
        {/* {vars.map((varNumber) => (
          <div key={varNumber} className="tw-flex tw-flex-col">
            <label className="tw-text-sm tw-font-medium tw-mb-1">
              Variable {varNumber}:
            </label>
            <input
              type="text"
              value={variables[varNumber] || ''}
              onChange={(e) => onVariableChange(varNumber, e.target.value)}
              placeholder={`Enter value for {{${varNumber}}}`}
              className="form-control input-field-ui"
            />
          </div>
        ))} */}
      </div>
    </div>
  );
};

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center tw-z-[9999]">
      <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-w-11/12 tw-max-w-4xl tw-h-[90vh] tw-flex tw-flex-col tw-relative">
        {children}
      </div>
    </div>,
    document.body
  );
};

const CreateMessage = ({whatsTemplateData,selectedTemplate,setSelectedTemplate}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempSelectedTemplate, setTempSelectedTemplate] = useState(null);
  const [variables, setVariables] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  /*const templates = [
    { 
      name: 'coach_message_1', 
      body: 'Hi {{1}}, I am your coach {{2}} we will be doing your lifestyle changes on here. Please let me know if you have any diet restrictions or allergies.',
      type: 'text'
    },
    { 
      name: 'coach_message_2_ut', 
      body: 'Good morning, Ready to make some lifestyle changes?',
      type: 'text'
    },
    { 
      name: 'live_welcome_1_rev', 
      body: "Hello 👋\n\nYou're on the verge of a significant health breakthrough!\n\nWith GOQii, you're tapping into a comprehensive health platform that will support you every step of the way on your {{1}} journey. 🌟\n\nImagine easy access to personalised health insights, coaching & a supportive community—all within the GOQii app! This journey is about more than just managing diabetes; it's about embracing a vibrant, healthier life. 💪 🌿\n\nReady to start this new chapter together? Let's do this! 🚀",
      footer: '#BETHEFORCE',
      buttons: [{ text: 'Download GOQii', url: 'https://link.interakt.ai/api/wa/{{1}}' }],
      type: 'text'
    }
  ];*/

 

  const handleOpenModal = () => {
    setIsModalOpen(true);
    // Initialize temporary selection with current selection
    setTempSelectedTemplate(selectedTemplate);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTempSelectedTemplate(null);
  };

  // const handleSelectTemplate = (template) => {
  //   const formattedTemplate = {
  //     ...template,
  //     body: template.templateBody.replace(/\\n/g, '<br />')
  //   };
  //   setSelectedTemplate(formattedTemplate);
  //   setVariables({});
  // };

  const handleSelectTemplate = (template) => {
    // Only update the temporary selection
    const formattedTemplate = {
      ...template,
      body: template.templateBody.replace(/\\n/g, '<br />')
    };
    setTempSelectedTemplate(formattedTemplate);
  };

  const handleChooseTemplate = () => {
    // Update the actual selected template only when "Choose Template" is clicked
    if (tempSelectedTemplate) {
      setSelectedTemplate(tempSelectedTemplate);
      setVariables({});
      setIsModalOpen(false);
    }
  };

  const handleVariableChange = (varNumber, value) => {
    setVariables(prev => ({
      ...prev,
      [varNumber]: value
    }));
  };

  return (
    <div className="tw-relative">
      <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Message</h3>
      <div className="tw-border tw-border-gray-300 tw-rounded tw-p-4 tw-mb-4">
        {selectedTemplate ? (
          <>
            <TemplateMockup template={selectedTemplate} variables={variables} />
          </>
        ) : (
          <div className="tw-text-center tw-text-gray-500">No Template Selected yet</div>
        )}
      </div>
      <button 
        className="btn btn-success uplaod-file-btn"
        onClick={handleOpenModal}
      >
        Choose template
      </button>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h2 className="tw-text-xl tw-text-gray-600 tw-font-bold">Select Template</h2>
          <span 
            className="tw-bg-white tw-text-gray-500 tw-font-bold tw-text-xl tw-cursor-pointer tw-p-2 tw-rounded hover:tw-text-gray-800"
            onClick={handleCloseModal}
          >
            ✕
          </span>
        </div>

        <div className="tw-flex tw-space-x-4 tw-flex-grow tw-overflow-hidden">
          <div className="tw-w-1/3 tw-flex tw-flex-col">
            <div className="form-group" style={{width: "100%"}}>
              <input
                type="text"
                placeholder="Search Template"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control input-field-ui ps-5"
              />
              <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
            </div>
            <div className="tw-overflow-y-auto tw-flex-grow tw-mt-2">
              {whatsTemplateData?.map((template, index) => (
                <TemplateItem 
                  key={index} 
                  template={template} 
                  onSelect={handleSelectTemplate}
                  isSelected={tempSelectedTemplate && tempSelectedTemplate.templateName === template.templateName}
                />
              ))}
            </div>
          </div>

          <div className='vr' />

          <div className="tw-w-2/3 tw-overflow-y-auto">
            {tempSelectedTemplate && (
              <div className="tw-flex tw-flex-col">
                <div className="tw-mb-6 tw-bg-gray-50 tw-p-4 tw-rounded-lg">
                  <h4 className="tw-font-bold tw-mb-4">Template Details:</h4>
                  
                  <div className="tw-mb-4">
                    <h5 className="tw-font-bold tw-mb-2">Body:</h5>
                    <p className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200" 
                       dangerouslySetInnerHTML={{ __html: tempSelectedTemplate.body.replace(/<br \/>/g, '\n') }}>
                    </p>
                  </div>

                  {tempSelectedTemplate.footer && (
                    <div className="tw-mb-4">
                      <h5 className="tw-font-bold tw-mb-2">Footer:</h5>
                      <p className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
                        {tempSelectedTemplate.footerContent}
                      </p>
                    </div>
                  )}

                  {tempSelectedTemplate.buttons && (
                    <div className="tw-mb-4">
                      <h5 className="tw-font-bold tw-mb-2">Buttons:</h5>
                      <div className="tw-bg-white tw-p-3 tw-rounded tw-border tw-border-gray-200">
                        {tempSelectedTemplate.buttons.map((button, index) => (
                          <div key={index} className="tw-mb-2 last:tw-mb-0">
                            <p><span className="tw-font-semibold">Text:</span> {button.text}</p>
                            {button.url && (
                              <p><span className="tw-font-semibold">URL:</span> {button.url}</p>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <VariableInputs 
                  template={tempSelectedTemplate}
                  variables={variables}
                  onVariableChange={handleVariableChange}
                />

                <div>
                  <h5 className="tw-font-bold tw-mb-4">Preview:</h5>
                  <TemplateMockup template={tempSelectedTemplate} variables={variables} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="tw-mt-6 tw-flex tw-justify-center tw-gap-4">
          <button 
            className="btn btn-success uplaod-file-btn"
            onClick={handleChooseTemplate}
            disabled={!tempSelectedTemplate}
          >
            Choose Template
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateMessage;