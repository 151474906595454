import React, { useState, useEffect } from "react";
import CalendarDatePicker from "../CalendarDatePicker";
import { fetchProgramPerformanceData, fetchProgramUsers, fetchProgramList, fetchProgramUsersTwo, fetchProgramUsersProgramCare, pathwayBreakupProgram } from "../../../services/medengage/Service";
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MembesAccrossPathwayMonthsBar from "../DashboardGraphsCNP/MembesAccrossPathwayMonthsBar";
import HealthRiskAssessmentsBar from "../DashboardGraphsCNP/HealthRiskAssessmentsBar";
import MemberPathwayStatusPie from "../DashboardGraphs/MemberPathwayStatusPie";
import { decryptData } from "../../utils/Utils";

function ContentProgramPerformanceDetail() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [programOverview, setProgramOverview] = useState('');
    const [programGraph, setProgramGraph] = useState('');
    const [selectedCondition, setSelectedCondition] = useState('');
    const [navigatorStats, setNavigatorStats] = useState({ 'onboardedMembers': 0, 'enrollMembers': 0, 'extraEntry': 0, 'totalOnboard': 0, 'enrollConversion': 0, 'pathwayAssigned': 0, 'adherenceRate': 0, 'pathwayInProgress': 0, 'pathwayCompleted': 0, 'deEnrolled': 0, 'notEnrollMembers': 0, 'cnConverstionEnrolled': 0, 'untouched': 0 });
    const [loading, setLoading] = useState(true);
    const [programList, setProgramList] = useState([]);
    const [conditionList, setConditionList] = useState([]);
    const [pathwayBreakupResult, setPathwayBreakupResult] = useState([]);
    const [statsValue, setStatsValue] = useState({
        'memberProgram': '', 'memberPathway': '', 'currentPathwayCount': '', 'totalMemberPathway': '', 'totalMemberCurrentMonth': '', 'avgMembersPerCareCount': '', 'totalAppointmentsCount': '', 'succesfulAppointmentsCount': '', 'appointmentCompeltionRateCount': '', 'careNavigationTransactionsCount': '', 'hramemberCount': '', 'smokingCount': '', 'stressCount': '',
        'enrollWithDate': '', 'pathwayProgressWithDate': '', 'pathwayCompleteWithDate': '', 'deEnrollWithDate': ''
    });

    const [statsValueNew, setStatsValueNew] = useState({
        'serviceTotal': '', 'serviceComplete': '', 'serviceRate': '',
        'homeCareTotal': '', 'homeCareComplete': '', 'homeCareRate': '',
        'teleHealthTotal': '', 'teleHealthComplete': '', 'teleHealthRate': '',
        'labCareTotal': '', 'labCareComplete': '', 'labCareRate': '',
        'medicationsTotal': '', 'medicationsComplete': '', 'medicationsRate': ''
    });

    const [seriesGraph, setSeriesGraph] = useState({
        memberCategory: [],
        memberPathway: [],
        currentPathway: [],
        pathwayMonth: [],
        ageDistribution: [],
        genderMember: [],
        memberTypeFamily: [],
        cityGraph: [],
        homeCareGraph: [],
        labHealthGraph: [],
        medicationGraph: [],
        hraGraph: [],
        smokingGraph: [],
        stressGraph: [],
        currentEnrollMonth: [],
    })

    useEffect(() => {
        fetchDropDownList(programGraph);
    }, []);

    useEffect(() => {

        const performFilterEffectNew = async () => {
            filterDataProgram();
        };

        performFilterEffectNew();
    }, [programOverview]);

    useEffect(() => {
        const performFilterEffect = async () => {
            setLoading(true);
            await filterData();
        };

        performFilterEffect();
    }, [startDate, endDate, programGraph, selectedCondition]);

    const filterDataProgram = async () => {
        await fetchData();
    };

    const filterData = async () => {
        await fetchMembersProgram();
        await fetchMembersProgramCare();
        setLoading(false);
        await fetchMembersProgramTwo();
        await fetchPathwayBreakup();
    };

    const fetchDropDownList = async (programGraph) => {
        await fetchProgramList(programGraph)
            .then((response) => {
                setProgramList(response.data.data.programList);
                setConditionList(response.data.data.conditionList);
            });
    }

    const fetchData = async () => {
        await fetchProgramPerformanceData()
            .then((response) => {
                const statsData = response.data.data.memberData;
                setNavigatorStats(prevState => ({
                    ...prevState, cnConverstionEnrolled: statsData.cnConverstionEnrolled, notEnrollMembers: statsData.notEnrollMembers, onboardedMembers: statsData.onboardedMembers, enrollMembers: statsData.enrollMembers, extraEntry: statsData.extraAddCare, totalOnboard: statsData.finalOnboard, enrollConversion: statsData.enrollRate, pathwayAssigned: statsData.pathwayAssigned, adherenceRate: statsData.adherenceRate, pathwayInProgress: statsData.pathwayActive, pathwayCompleted: statsData.pathwayCompeleted, deEnrolled: statsData.pathwayDeenroll, untouched: statsData.untouched
                }));
            });
    }

    const fetchMembersProgram = async () => {
        await fetchProgramUsers(formatDate(startDate), formatDate(endDate), programGraph, selectedCondition)
            .then((response) => {
                setStatsValue(prevState => ({ ...prevState, memberProgram: response.data.data.totalMembersProgram, memberPathway: response.data.data.totalMembersPathway, currentPathwayCount: response.data.data.totalCurrentPathway, totalMemberPathway: response.data.data.totalMemberPathway, totalMemberCurrentMonth: response.data.data.totalMemberCurrentMonth }));
                setSeriesGraph(prevState => ({
                    ...prevState, memberCategory: response.data.data.memberProgram.series.data, memberPathway: response.data.data.memberPathwayStatus.series.data, currentPathway: response.data.data.memberCurrentPathway.series.data,
                    pathwayMonth: response.data.data.pathwayMonth, currentEnrollMonth: response.data.data.currentEnrollMonth
                }));
            });
    }

    const fetchMembersProgramTwo = async () => {
        await fetchProgramUsersTwo(formatDate(startDate), formatDate(endDate), programGraph, selectedCondition)
            .then((response) => {
                setStatsValue(prevState => ({ ...prevState, hramemberCount: response.data.data.hraMemberCount, smokingCount: response.data.data.smokingMemberCount, stressCount: response.data.data.stressMemberCount }));
                setSeriesGraph(prevState => ({
                    ...prevState, cityGraph: response.data.data.cityMember, ageDistribution: response.data.data.ageMember, genderMember: response.data.data.genderMember.series.data, memberTypeFamily: response.data.data.memberType.series.data, hraGraph: response.data.data.hraMember, smokingGraph: response.data.data.smokingData.series.data, stressGraph: response.data.data.stressData.series.data
                }));
            });
    }

    const fetchMembersProgramCare = async () => {
        await fetchProgramUsersProgramCare(formatDate(startDate), formatDate(endDate), programGraph, selectedCondition)
            .then((response) => {
                setStatsValue(prevState => ({
                    ...prevState, avgMembersPerCareCount: response.data.data.avgMembersPerCare, totalAppointmentsCount: response.data.data.totalAppointments, succesfulAppointmentsCount: response.data.data.succesfulAppointments, appointmentCompeltionRateCount: response.data.data.appointmentCompeltionRate, careNavigationTransactionsCount: response.data.data.careNavigationTransactions,
                    enrollWithDate: response.data.data.enrollMembers, pathwayProgressWithDate: response.data.data.pathwayProgress, pathwayCompleteWithDate: response.data.data.pathwayComplete, deEnrollWithDate: response.data.data.deEnrollCount
                }));
                setStatsValueNew(prevState => ({
                    ...prevState, serviceTotal: response.data.data.totalService, serviceComplete: response.data.data.completeService, serviceRate: response.data.data.servicePercentage,
                    homeCareTotal: response.data.data.totalHomeCare, homeCareComplete: response.data.data.completeHomeCare, homeCareRate: response.data.data.homeCarePercentage,
                    teleHealthTotal: response.data.data.totalTele, teleHealthComplete: response.data.data.completeTele, teleHealthRate: response.data.data.telePercentage,
                    labCareTotal: response.data.data.totalLabCare, labCareComplete: response.data.data.completeLabCare, labCareRate: response.data.data.labCarePercentage,
                    medicationTotal: response.data.data.totalMedication, medicationComplete: response.data.data.completeMedication, medicationRate: response.data.data.medicationPercentage
                }));
                setSeriesGraph(prevState => ({
                    ...prevState, homeCareGraph: response.data.data.homeCare, labHealthGraph: response.data.data.labHealth, medicationGraph: response.data.data.medication
                }));
            });
    }


    const fetchPathwayBreakup = async () => {
        if (selectedCondition > 0) {
            //await pathwayBreakupProgram(formatDate(startDate), formatDate(endDate), programGraph, selectedCondition)
            await pathwayBreakupProgram(selectedCondition)
                .then((response) => {
                    setPathwayBreakupResult(response.data.data.info);
                });

        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateChange = ({ start, end }) => {

        setStartDate(start);
        setEndDate(end);
    };

    const handleChangeCondition = (event) => {
        setSelectedCondition(event.target.value);
    };

    const handleChangeProgramOverview = (event) => {
        setProgramOverview(event.target.value);
    };

    const handleChangeProgramGraph = (event) => {
        setProgramGraph(event.target.value);
    };

    const renderPP1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members who have enrolled in the program (Enrolled + Enroll later).
        </Tooltip>
    );

    const renderPP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members enrolled in the program.
        </Tooltip>
    );

    const renderPP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Adherence rates of members to pathways.
        </Tooltip>
    );

    const renderPP4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members currently in the pathway
        </Tooltip>
    );

    const renderPP5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members completed the pathway
        </Tooltip>
    );

    const renderPP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members de-enrolled from the pathway
        </Tooltip>
    );

    const renderPP7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Status of members onboarded in each program.
        </Tooltip>
    );

    const renderPP8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Status of members onboarded: Completed, In Progress, De-Enrolled.
        </Tooltip>
    );

    const renderPP9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members in various pathways.
        </Tooltip>
    );

    const renderPP10Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members in pathway journey
        </Tooltip>
    );

    const renderPP11Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Count of services booked
        </Tooltip>
    );

    const renderPP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Count of services completed
        </Tooltip>
    );

    const renderPP13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Percentage of services completion
        </Tooltip>
    );

    const renderPP14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Count of Home Health Care services booked by CareNavigators.
        </Tooltip>
    );

    const renderPP15Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Count of Lab services booked by CareNavigators.
        </Tooltip>
    );

    const renderPP16Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Count of Medication services booked by CareNavigators.
        </Tooltip>
    );

    const renderPP17Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Proportion of members in each risk category.
        </Tooltip>
    );

    const renderPP18Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Proportion of members in each risk category.
        </Tooltip>
    );

    const renderPP19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Proportion of members in each risk category.
        </Tooltip>
    );

    const renderMCI19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members segmented by age groups.
        </Tooltip>
    );

    const renderPP20Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members gender distribution.
        </Tooltip>
    );

    const renderPP21Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members city distribution.
        </Tooltip>
    );

    const renderPP22Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Employee vs. Family members insurance distribution.
        </Tooltip>
    );

    const renderPP23Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Summary of  Services.
        </Tooltip>
    );

    const renderPP24Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Summary of Home Health Care Services.
        </Tooltip>
    );

    const renderPP25Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Summary of TeleHealth Services.
        </Tooltip>
    );

    const renderPP26Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Summary of Lab Services.
        </Tooltip>
    );

    const renderPP27Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Summary of  Medications Services.
        </Tooltip>
    );

    const renderPP28Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members assigned a Pathway by CareNavigators after first call.
        </Tooltip>
    );

    useEffect(() => {
        fetchDropDownList(programGraph)
    }, [programGraph])

    return (
        <>
            <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md">
                        <h1 className="main-title">Population Compliance & Success</h1>
                    </div>
                </div>

                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/programperformance.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
                        </div>
                        <div className="col-md">
                            <p className="mb-3">Provides admins with an overview of program and condition-specific data, including key performance indicators (KPIs) on member engagement and service utilization.</p>
                        </div>
                    </div>
                </div>

                <div className="content-ProgramPerformance mb-3">
                    <div className="row align-items-center mb-2">
                        <div className="col"><h1 className='dashboard-graph-title'>Program Overview</h1></div>
                        <div className="col-3">
                            {/*<select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example" value={programOverview} onChange={handleChangeProgramOverview}>
                                <option value="">Select Program</option>
                                {programList.map(option => (
                                    <option key={option.programId} value={option.programId}>{option.programName}</option>
                                ))}
                            </select>*/}
                        </div>
                    </div>

                    <div className="row text-center px-2">
                        <div className="col-auto px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid rgb(18, 121, 190)', height: "100%" }}>
                                <div className="card-body p-2 d-flex align-items-center">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.onboardedMembers}</h1>
                                            <h5 className="card-text1">Onboarded
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Members onboarded by the member engagement team</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid rgb(18, 121, 190)', height: "100%" }}>
                                <div className="card-body p-2 d-flex align-items-center">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.extraEntry}</h1>
                                            <h5 className="card-text1">Extra Manual Onboarded
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Members manually uploaded into the system by CareNavigators.</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid rgb(18, 121, 190)', height: "100%" }}>
                                <div className="card-body p-2 d-flex align-items-center">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.totalOnboard}</h1>
                                            <h5 className="card-text1">Total Onboarded
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Combined total of members onboarded by both Member Engagement and CareNavigators.</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid rgb(18, 121, 190)', height: "100%" }}>
                                <div className="card-body p-2 d-flex align-items-center">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.enrollMembers}</h1>
                                            <h5 className="card-text1">Enrolled
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Members assigned a Pathway by CareNavigators after first call.</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body p-2 d-flex align-items-center">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.notEnrollMembers}</h1>
                                            <h5 className="card-text1">Not Enrolled
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Members who are not interested in the program.</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body p-2 d-flex align-items-center">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.untouched}</h1>
                                            <h5 className="card-text1">Enrollment Pending
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Members who have not yet been pathway assigned</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', backgroundColor: "rgb(208 236 255)", height: "100%" }}>
                                <div className="card-body p-2">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="text-start">ENROLLED</h5>
                                            <h5 className="text-start">{navigatorStats.enrollMembers}/{navigatorStats.totalOnboard}</h5>
                                            <h5 className="card-text1" >Conversion Rate
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Rate of conversion from onboarded to enrolled members.</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        <div className="col pt-4">
                                            <h1 className="card-title1">= {navigatorStats.cnConverstionEnrolled}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{navigatorStats.onboardedMembers}</h1>
                                        <h5 className="card-text1">Members Onboarded</h5>
                                    </div>
                                    <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP1Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{navigatorStats.enrollMembers}</h1>
                                        <h5 className="card-text1">Members Enrolled</h5>
                                    </div>
                                    <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{navigatorStats.enrollConversion}</h1>
                                        <h5 className="card-text1">Conversion Rate</h5>
                                    </div>
                                    <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP28Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="row text-center px-2 mt-2">
                        {/* <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{navigatorStats.pathwayAssigned}</h1>
                                        <h5 className="card-text1">Pathway Assigned</h5>
                                    </div>
                                    <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div> 

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{navigatorStats.adherenceRate}</h1>
                                        <h5 className="card-text1">Pathway Adherence Rate</h5>
                                    </div>
                                    <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP3Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>*/}

                        <div className="col-4 px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body p-2">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.pathwayInProgress}</h1>
                                            <h5 className="card-text1">Pathway In Progress
                                                <OverlayTrigger placement="top" overlay={renderPP4Tooltip}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP4Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.pathwayCompleted}</h1>
                                            <h5 className="card-text1">Pathway Completed
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Number of members that have completed the pathway</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP5Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4 px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.deEnrolled}</h1>
                                            <h5 className="card-text1">Members De-Enrolled
                                                <OverlayTrigger placement="top" overlay={renderPP6Tooltip}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP6Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row align-items-center mt-4 pb-3 sticky-top-bar">
                    <div className="col-md-4">
                        <select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example" value={programGraph} onChange={handleChangeProgramGraph}>
                            <option value="">Select Program</option>
                            {programList.map(option => (
                                <option key={option.programId} value={option.programId}>{option.programName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select className="form-select input-field-ui" aria-label="Default select example" value={selectedCondition} onChange={handleChangeCondition}>
                            <option value="">Select Condition</option>
                            {conditionList.map(option => (
                                <option key={option.conditionId} value={option.conditionId}>{option.conditionName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                </div>


                <div className="row">

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Members Onboarded
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip className="custom-tooltip">Bifurcation of members onboarded under the seelcted program</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </span>
                                <span className="dashboard-graph-number">{statsValue.memberProgram}</span>
                            </h1>
                            {/* <MembersAccrossProgramPie /> */}

                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.memberCategory ? (
                                    <MemberPathwayStatusPie series={seriesGraph.memberCategory} />
                                ) : (
                                    <div className="emty-pie">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Pathway Assigned Status
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip className="custom-tooltip">Total pathways assigned to the members</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </span>
                                <span className="dashboard-graph-number">{statsValue.memberPathway}</span>
                            </h1>

                            {/* <MemberPathwayAssignedStatusPie /> */}

                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.memberPathway ? (
                                    <MemberPathwayStatusPie series={seriesGraph.memberPathway} />
                                ) : (
                                    <div className="emty-pie">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Pathway Breakup (In-Progress)
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip className="custom-tooltip">Pathway breakup of in-progress members</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </span>
                                <span className="dashboard-graph-number">{statsValue.currentPathwayCount}</span>
                            </h1>

                            {/* <PathwayBreakupPie/> */}
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.currentPathway ? (
                                    <MemberPathwayStatusPie series={seriesGraph.currentPathway} />
                                ) : (
                                    <div className="emty-pie">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Members Across Pathway Months
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip className="custom-tooltip">Number of members in progress in pathway journey across months</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </span>
                            </h1>
                            <h2 className="dashboard-graph-subtitle">Pathway In Progress: <span>{statsValue.totalMemberPathway}</span></h2>
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.pathwayMonth.dataValue ? (
                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.pathwayMonth} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Daily Enrollment
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip className="custom-tooltip">Number of members enrolling daily</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </span>
                            </h1>
                            <h2 className="dashboard-graph-subtitle">Members: <span>{statsValue.totalMemberCurrentMonth}</span></h2>
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.currentEnrollMonth.dataValue ? (
                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.currentEnrollMonth} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                </div>

                <div className="content-ProgramPerformance mb-3">

                    <div className="row text-center px-2 mb-2">
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.enrollWithDate}</h1>
                                            <h5 className="card-text1">Enrolled</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top" overlay={renderPP28Tooltip}>
                                                <i className='bx bx-info-circle'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.pathwayProgressWithDate}</h1>
                                            <h5 className="card-text1">Pathway In Progress</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top" overlay={renderPP4Tooltip}>
                                                <i className='bx bx-info-circle'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.pathwayCompleteWithDate}</h1>
                                            <h5 className="card-text1">Pathway Completed</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Number of members that have completed the pathway</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.deEnrollWithDate}</h1>
                                            <h5 className="card-text1">Members De-Enrolled</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top" overlay={renderPP6Tooltip}>
                                                <i className='bx bx-info-circle'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row text-center px-2">
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.avgMembersPerCareCount}</h1>
                                            <h5 className="card-text1">Avg members per CareNavigator</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Average number of members assigned per care navigator</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.totalAppointmentsCount}</h1>
                                            <h5 className="card-text1">CareNavigators Total Appointments</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Total number of care navigator appointments booked.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.succesfulAppointmentsCount}</h1>
                                            <h5 className="card-text1">CareNavigators Successful Appointments</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Number of appointments successfully completed by care navigators</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.appointmentCompeltionRateCount}</h1>
                                            <h5 className="card-text1">Appointment Completion Rate</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Percentage of appointments completion</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.careNavigationTransactionsCount}</h1>
                                            <h5 className="card-text1">CareNavigator Transactions</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Total number of transactions by CareNavigator</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row text-center px-2 mt-2">
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="text-start">Services</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top" overlay={renderPP23Tooltip}>
                                                <i className='bx bx-info-circle'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Booked</h5>
                                        <h5 className="col text-end">{statsValueNew.serviceTotal}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completed</h5>
                                        <h5 className="col text-end">{statsValueNew.serviceComplete}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completion Rate</h5>
                                        <h5 className="col text-end">{statsValueNew.serviceRate}</h5>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="text-start">Home Health Care Referrals</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top" overlay={renderPP24Tooltip}>
                                                <i className='bx bx-info-circle'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Booked</h5>
                                        <h5 className="col text-end">{statsValueNew.homeCareTotal}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completed</h5>
                                        <h5 className="col text-end">{statsValueNew.homeCareComplete}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completion Rate</h5>
                                        <h5 className="col text-end">{statsValueNew.homeCareRate}</h5>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="text-start">TeleHealth Referrals</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top" overlay={renderPP25Tooltip}>
                                                <i className='bx bx-info-circle'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Booked</h5>
                                        <h5 className="col text-end">{statsValueNew.teleHealthTotal}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completed</h5>
                                        <h5 className="col text-end">{statsValueNew.teleHealthComplete}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completion Rate</h5>
                                        <h5 className="col text-end">{statsValueNew.teleHealthRate}</h5>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="text-start">Lab Referrals</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top" overlay={renderPP26Tooltip}>
                                                <i className='bx bx-info-circle'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Booked</h5>
                                        <h5 className="col text-end">{statsValueNew.labCareTotal}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completed</h5>
                                        <h5 className="col text-end">{statsValueNew.labCareComplete}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completion Rate</h5>
                                        <h5 className="col text-end">{statsValueNew.labCareRate}</h5>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="text-start">Medication Referrals</h5>
                                        </div>
                                        <div className="col-auto">
                                            <OverlayTrigger placement="top" overlay={renderPP27Tooltip}>
                                                <i className='bx bx-info-circle'></i>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Booked</h5>
                                        <h5 className="col text-end">{statsValueNew.medicationTotal}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completed</h5>
                                        <h5 className="col text-end">{statsValueNew.medicationComplete}</h5>
                                    </div>
                                    <div className="row">
                                        <h5 className="card-text1 col">Completion Rate</h5>
                                        <h5 className="col text-end">{statsValueNew.medicationRate}</h5>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row">

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Home Health Care Referrals
                                    <OverlayTrigger placement="top" overlay={renderPP14Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>
                            {/* <ReferralsGraph dataColumns={referralsHomeColumns} dataValues={referralsHomeData} /> */}
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.homeCareGraph.dataValue ? (
                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.homeCareGraph} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Lab Referrals
                                    <OverlayTrigger placement="top" overlay={renderPP15Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>
                            {/* <ReferralsGraph dataColumns={referralsLabColumns} dataValues={referralsLabData} /> */}
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.labHealthGraph.dataValue ? (
                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.labHealthGraph} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Medication Referrals
                                    <OverlayTrigger placement="top" overlay={renderPP16Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>
                            {/* <ReferralsGraph dataColumns={referralsMedicationColumns} dataValues={referralsMedicationData} /> */}
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.medicationGraph.dataValue ? (
                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.medicationGraph} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                </div>

                <div className="row">

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Health Risk Assessment
                                    <OverlayTrigger placement="top" overlay={renderPP17Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>
                            <h2 className="dashboard-graph-subtitle">Members: <span>{statsValue.hramemberCount}</span></h2>



                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.hraGraph.dataValue ? (
                                    <HealthRiskAssessmentsBar dataMonth={seriesGraph.hraGraph} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Smoking
                                    <OverlayTrigger placement="top" overlay={renderPP18Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>
                            <h2 className="dashboard-graph-subtitle">Members: <span>{statsValue.smokingCount}</span></h2>

                            {/* <SmokingPie/> */}
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.smokingGraph ? (
                                    <MemberPathwayStatusPie series={seriesGraph.smokingGraph} />
                                ) : (
                                    <div className="emty-pie">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Stress
                                    <OverlayTrigger placement="top" overlay={renderPP19Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>
                            <h2 className="dashboard-graph-subtitle">Members: <span>{statsValue.stressCount}</span></h2>

                            {/* <StressPie/> */}

                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.stressGraph ? (
                                    <MemberPathwayStatusPie series={seriesGraph.stressGraph} />
                                ) : (
                                    <div className="emty-pie">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                </div>


                <div className="row">

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Age Group
                                    <OverlayTrigger placement="top" overlay={renderMCI19Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>

                            {/* <AgeGroupConversionBar /> */}
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.ageDistribution.dataValue ? (
                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.ageDistribution} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Gender Distribution
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip className="custom-tooltip">Gender wise Member distribution</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </span>
                            </h1>

                            {/* <GenderPie/> */}
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.genderMember ? (
                                    <MemberPathwayStatusPie series={seriesGraph.genderMember} />
                                ) : (
                                    <div className="emty-pie">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>Member Type
                                    <OverlayTrigger placement="top" overlay={renderPP22Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                            </h1>

                            {/* <FamilyDistribution /> */}
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.memberTypeFamily ? (
                                    <MemberPathwayStatusPie series={seriesGraph.memberTypeFamily} />
                                ) : (
                                    <div className="emty-pie">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title'>
                                <span>City Distribution
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip className="custom-tooltip">City wise Member distribution</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </span>
                            </h1>

                            {/* <CityDistributionBar /> */}

                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.cityGraph.dataValue ? (
                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.cityGraph} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
                {

                    selectedCondition > 0 &&
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title'>
                                    <span>Pathway Breakup
                                    </span>
                                </h1>
                                <div className="table-responsive mt-3">
                                    <div className="table-container">
                                        <table className="styled-table">
                                            <thead>
                                                <tr>
                                                    <th className="bg-white" rowSpan="2">Initial Pathway</th>
                                                    {
                                                        pathwayBreakupResult.map((initialPathway, initialPathwayIndex) => {
                                                            return <th key={initialPathwayIndex} className={`${initialPathwayIndex % 2 > 0 ? "table-title bg-lightblue brd-l brd-r" : "table-title"}`} colSpan={pathwayBreakupResult.length}>{initialPathway.pathwayName}</th>
                                                        })
                                                    }
                                                </tr>
                                                <tr>
                                                    {
                                                        pathwayBreakupResult.map((initialPathway, initialPathwayIndex) => {
                                                            return <th key={initialPathwayIndex} className={`${initialPathwayIndex % 2 > 0 ? "bg-white table-number brd-l brd-r" : "bg-white table-number"}`} colSpan={pathwayBreakupResult.length}>{initialPathway.initialPathwayCount}</th>
                                                        })
                                                    }
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th className="bg-white" rowSpan="3">Current Pathway</th>
                                                    {
                                                        pathwayBreakupResult.map((initialPathway, initialPathwayIndex) => {
                                                            return (
                                                                <React.Fragment key={initialPathwayIndex}>
                                                                    {
                                                                        initialPathway.currentPathways.map((currentPathway, currentPathwayIndex) => {
                                                                            return (
                                                                                <th key={currentPathwayIndex} className={`${initialPathwayIndex % 2 > 0 ? (currentPathwayIndex === 0) ? "bg-lightblue brd-l" : (currentPathwayIndex === initialPathway.currentPathways.length - 1) ? "bg-lightblue brd-r" : "bg-lightblue" : ""}`}>
                                                                                    {currentPathway.pathwayName}
                                                                                </th>
                                                                            );
                                                                        })
                                                                    }
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }

                                                </tr>
                                                <tr>
                                                    {
                                                        pathwayBreakupResult.map((initialPathway, initialPathwayIndex) => {
                                                            return (
                                                                <React.Fragment key={initialPathwayIndex}>
                                                                    {
                                                                        initialPathway.currentPathways.map((currentPathway, currentPathwayIndex) => {
                                                                            return (
                                                                                <td>
                                                                                    {currentPathway.currentPathwayCount}
                                                                                    <br />
                                                                                    <span className={`${currentPathway.currentPathwayArrowColor}-text`}>{currentPathway.currentPathwayPerc}% <i className={`bi bi-arrow-${currentPathway.currentPathwayArrow}`}></i></span>
                                                                                </td>
                                                                            );
                                                                        })
                                                                    }
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default ContentProgramPerformanceDetail;
