import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from "axios";
import { fetchHealthlockerFiles,fetchHealthlockerFileUrl } from '../services/Service';
import DataTable from 'react-data-table-component';

const UploadTeleHealthBook = (props) => {
    
    const [files, setFiles] = useState([]);
    const [fileUrlId, setFileUrlId] = useState("");
    const [allChecked, setAllChecked] = useState(false);
    const [checked, setChecked] = useState([]);
    // const [dropdownValues, setDropdownValues] = useState({});
    const [selectError, setSelectError] = useState('');

    
    useEffect(() => {
        const nonDisabledItems = files.filter(item => !item.disable);
        if (nonDisabledItems.length > 0) {
            setAllChecked(nonDisabledItems.length > 0 && checked.length === nonDisabledItems.length);
        } else {
            setAllChecked(false);
        }
    }, [checked, files]);


    useEffect(() => {
        getHealthlockerFiles();
    }, [])


    const getHealthlockerFiles = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHealthlockerFiles(props.userId,'all', ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setFiles(response.data.data.files);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const getHealthlockerFileUrl = async (fileId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHealthlockerFileUrl(fileId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    window.open(response.data.data.fileUrl, '_blank', 'noopener,noreferrer');
                    setFileUrlId("");
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleCheckboxChange = (fileId) => {
        setChecked(prevChecked =>
            prevChecked.includes(fileId) ? prevChecked.filter(item => item !== fileId) : [...prevChecked, fileId]
        );
    };

    const handleAllChange = (e) => {
        const { checked } = e.target;
        if (checked) {
            setChecked(files.filter(item => !item.disable).map(item => item.fileId));
        } else {
            setChecked([]);
        }
    };

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#e9e9e9',
                fontWeight: '700',
                fontSize: '16px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                backgroundColor: '#d1f2ee', // Default background color for rows
                '&:nth-of-type(odd)': {
                    backgroundColor: '#dcfffb', // Alternate color for odd rows
                },
                whiteSpace: 'normal',
                fontSize: '16px',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const columns = [
        {
            name: (
                <div>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckAll"
                        onChange={handleAllChange}
                        checked={allChecked}
                    />
                </div>
            ),
            cell: row => (
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={`flexCheck${row.fileId}`}
                    onChange={() => handleCheckboxChange(row.fileId)}
                    checked={checked.includes(row.fileId)}
                    disabled={row.disable}
                />
            ),
            sortable: false,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
        },
        {
            name: 'Report Type',
            selector: row => row.reportType,
            sortable: true,
        },
        {
            name: 'Uploaded By',
            selector: row => row.uploadedBy,
            sortable: true,
        },
        {
            name: 'View',
            cell: row => (
                <div>
                    <button onClick={() => {
                        setFileUrlId(row.fileId);
                        getHealthlockerFileUrl(row.fileId);
                    }} className={`btn btn-success health-locker-btn me-2 ${fileUrlId == row.fileId ? "hide" : ""}`}><i className="bi bi-eye"></i>
                    </button>
                    {
                        fileUrlId === row.fileId &&
                        <span><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "35px" }} alt="" /></span>
                    }
                </div>

            ),
            sortable: false,
        },
    ];


    const handleSubmit = async () => {
        let selectedData = [];
        let selectErrorTemp = false;

        for (let fileId of checked) {
            const row = files.find(item => item.fileId === fileId);

            selectedData.push({
                fileId
            });
        }


        try {

            if (selectedData.length == 0) {
                setSelectError('Select at least one file to upload')
                return;
            }

            // fetchHealthlockerFiles(JSON.stringify(selectedData))
            //     .then((response) => {
                   
            //     });

        } catch (error) {
            console.error('Error submitting data', error);
        }
    };
    
    return (
        <>
            <Modal id="externalMemberSearch" show={props.showUploadTelehealthModal} onHide={props.closeUploadTelehealthModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Attach Reports</h1>
                </Modal.Header>
                <Modal.Body>

                <div className='p-3' style={{borderRadius: '15px'}}>
                    <DataTable
                        columns={columns}
                        data={files}
                        pagination={false}
                        paginationPerPage={10}
                        striped
                        responsive
                        noHeader
                        noFooter
                        customStyles={customStyles}
                    />
                    {selectError && <div className="text-danger">{selectError}</div>}
                </div>
                
                <div class="col-md-12 text-center">
                    <button className='btn program-save-btn me-2 mt-2' onClick={handleSubmit}>Upload</button>
                </div>


                    {/* <div className='col-md-12'>
                        <div className='card card-task-appoint'>
                            <div className='card-body'>
                                <div className='card-text'>
                                    <div className='row align-items-center'>
                                        <div>
                                            <div className="table-responsive mt-2" style={{ maxHeight: "53vh" }}>
                                                <table className="table table-striped table-bordered mb-0">
                                                    <thead style={{ position: "sticky", top: "0" }}>
                                                        <tr>
                                                            <th scope="col">
                                                                Date 
                                                            </th>
                                                            <th scope="col">
                                                                Uploaded By
                                                            </th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            files.map((report, index) => {
                                                                return <tr key={index} style={{ verticalAlign: 'middle' }}>
                                                                    <td className='bg-td-green'>{report.date}</td>
                                                                    <td className='bg-td-green'>{report.uploadedBy}</td>
                                                                    <td className='bg-td-green'>
                                                                        <button onClick={() => {
                                                                            setFileUrlId(report.fileId);
                                                                            getHealthlockerFileUrl(report.fileId);
                                                                        }} className={`btn btn-success health-locker-btn me-2 ${fileUrlId == report.fileId ? "hide" : ""}`}><i className="bi bi-eye"></i>
                                                                        </button>
                                                                        {
                                                                            fileUrlId === report.fileId &&
                                                                            <span><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "35px" }} alt="" /></span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            })}

                                                        {
                                                            files.length === 0 &&
                                                            <tr><td colSpan={4}>No files found</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div >
                                    </div >
                                </div>
                            </div>
                        </div >
                    </div>          */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UploadTeleHealthBook