import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { whatsapp_list, whatsapp_send, whatsapp_message_status } from "../../../services/met/Service";

const CommunicationModal = ({
  isOpen,
  onClose,
  selectedChannel,
  memberMobileNo,
  userId, 
  memberName
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [selectedTab, setSelectedTab] = useState(selectedChannel);
  const [mobileNoChange, setMobileNoChange] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [variables, setVariables] = useState({});
  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState("");
  const [selectedTemplateError, setSelectedTemplateError] = useState("");
  const mobile = memberMobileNo;
  const [mobileNo, setMobileNo] = useState({
    mobileNo: mobile,
    copiedMobileNo: mobile
  });
  const [mobileNoDisplayMode, setMobileNoDisplayMode] = useState("display");
  // Convert templates for react-select
  const templateOptions = templates.map(template => ({
    value: template.templateId,
    label: template.templateName
  }));


  const [messageHistoryData, setMessageHistoryData] = useState([]); 
  const [activeTooltip, setActiveTooltip] = useState(null);


  async function fetchWhatsappMessageHistory (){ 

    try{ 
      const response = await whatsapp_message_status({ userId });
      if(response.data.code === 200){
        setMessageHistoryData(response.data.data?.templateData || []);
      }else { 
        console.log("error", response.data.message);
      }
    }catch(e){ 

    }
  }

  // Custom styles for react-select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '42px',
      borderColor: state.isFocused ? '#03335b' : '#e2e8f0',
      boxShadow: state.isFocused ? '0 0 0 1px #03335b' : null,
      '&:hover': {
        borderColor: '#03335b'
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#03335b'
        : state.isFocused
          ? '#e2e8f0'
          : null,
      color: state.isSelected ? 'white' : '#000',
      '&:active': {
        backgroundColor: '#03335b'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6b7280'
    })
  };

  // const messageHistoryData = [
  //   {
  //     mobileNumber: "8082055140",
  //     message: <>
  //       Hello AbdulAziz Al Hariri,<br/>
  //       Please find the program<br/> 
  //       details in attached documents.<br/><br/>
  //       To stop receiving these<br/>
  //       messages, reply STOP
  //     </>,
  //     statusAndDate: "Sent on 28th Oct, 2024"
  //   },
  //   {
  //     mobileNumber: "9867254645",
  //     message: <>
  //       Hello AbdulAziz Al Hariri,<br/>
  //       Your call has been scheduled<br/>
  //       for 28 Oct 2024 at 13:58 PM.<br/><br/>
  //       To stop receiving these<br/>
  //       messages, reply STOP
  //     </>,
  //     statusAndDate: "Delivered on 29th Oct, 2024"
  //   },
  //   {
  //     mobileNumber: "9867992020",
  //     message: "Welcome to CareConnect Program",
  //     statusAndDate: <>Undelivered on<br/>27th Oct, 2024 (1)</>
  //   }
  // ];



    const MessageHistory = ({ messageHistoryData }) => {
      console.log(messageHistoryData);     
    return (
      <div className="tw-w-full">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <h5 className="tw-text-gray-600 tw-font-medium">WhatsApp Message History</h5>
          <button 
            className="add-new-program-btn"
            onClick={() => fetchWhatsappMessageHistory()}
          >
            Refresh
          </button>
        </div>
  
        <div className="tw-border tw-rounded-lg tw-relative tw-z-0">
          <table className="tw-w-full">
            <thead>
              <tr className="tw-bg-gray-50">
                <th className="tw-px-4 tw-py-2 tw-text-left tw-font-medium tw-text-gray-600 tw-border-b tw-text-xs">Mobile Number</th>
                <th className="tw-px-4 tw-py-2 tw-text-left tw-font-medium tw-text-gray-600 tw-border-b tw-text-xs">Message</th>
                <th className="tw-px-4 tw-py-2 tw-text-left tw-font-medium tw-text-gray-600 tw-border-b tw-text-xs">Status & Date</th>
              </tr>
            </thead>
            <tbody>
  {messageHistoryData.length > 0 ? (
    messageHistoryData.map((row, index) => (
      <tr key={index} className="hover:tw-bg-gray-50">
        <td className="tw-px-4 tw-py-3 tw-border-b tw-text-gray-600 tw-text-xs">{row.mobileNumber}</td>
        <td className="tw-px-4 tw-py-3 tw-border-b tw-text-gray-600 tw-text-xs">{row.message}</td>
        <td className="tw-px-4 tw-py-3 tw-border-b tw-text-gray-600 tw-text-xs">
          {row.statusAndDate}
          {row.statusAndDate?.split(" on ")[0] === "Undelivered" ? (
                        <div className="tw-relative tw-inline-block tw-ml-1">
                            <i
                                className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2"
                                onMouseEnter={() => setActiveTooltip(index)}
                                onMouseLeave={() => setActiveTooltip(null)}
                            ></i>
                            {activeTooltip === index && (
                                <div
                                    className="tw-absolute tw-z-50 tw-w-96 -tw-left-80 tw-top-5 tw-bg-white tw-rounded-lg tw-border tw-border-gray-500 tw-shadow-lg tw-p-4"
                                    onMouseEnter={() => setActiveTooltip(index)}
                                    onMouseLeave={() => setActiveTooltip(null)}
                                >
                                    <div className="tw-relative">
                                        <p className="tw-text-sm tw-font-bold tw-text-red-600 tw-mb-2">
                                            ERROR: {row.errorCode}
                                        </p>
                                        <p className="tw-text-sm tw-text-gray-600 tw-mb-3">
                                            Meta chose not to deliver this WhatsApp marketing message
                                        </p>
                                        <div className="tw-flex tw-flex-col tw-gap-2">
                                            <span className='tw-text-sm'>
                                            <a
                                                href={`https://www.twilio.com/docs/api/errors/${row.errorCode}`}
                                                className="tw-text-blue-500 hover:tw-underline tw-text-sm tw-mr-1"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                Read More 
                                            </a>
                                            about Error code <strong>{row.errorCode}</strong>
                                            </span>
                                            <span className='tw-text-sm'>
                                            Possible Solution: {row.solution}
                                            {/* <a
                                                href="https://developers.facebook.com/docs/whatsapp/cloud-api/guides/send-message-templates#per-user-marketing-template-message-limits"
                                                className="tw-text-blue-500 hover:tw-underline tw-text-sm tw-mr-1"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                Click here
                                            </a>
                                            to understand more Message Limits from Meta */}
                                            </span>
                                        </div>
                                        {/* Arrow pointer for tooltip */}
                                        {/* <div className="tw-absolute -tw-bottom-4 tw-left-2 tw-w-4 tw-h-4 tw-bg-white tw-border-b tw-border-r tw-border-gray-200 tw-transform tw-rotate-45"></div> */}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                      <span></span>
                    )}
          </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="3" className="tw-text-center tw-py-4">
        <h5 className="tw-text-gray-500">No Data Found</h5>
        <p className="tw-text-gray-400 tw-italic tw-text-xs">No messages have been sent to this member. Once sent, they’ll appear here.</p>
      </td>
    </tr>
  )}
</tbody>
          </table>
        </div>
      </div>
    );
  };


  // Convert HTML content to plain text for preview
  // const htmlToText = (html) => {
  //   const doc = new DOMParser().parseFromString(html, "text/html");
  //   return doc.body.textContent || "";
  // };

  // Function to extract variables from template content
  // const extractVariables = (content) => {
  //   const matches = content.match(/{{(\d+)}}/g) || [];
  //   return matches.map((match) => match.replace(/[{}]/g, ""));
  // };

  // Function to replace variables in content
  const replaceVariables = (content) => {
    let replacedContent = content;
    Object.entries(variables).forEach(([key, value]) => {
      replacedContent = replacedContent.replace(
        new RegExp(`{{${key}}}`, "g"),
        value || `{{${key}}}`
      );
    });
    return replacedContent;
  };

  // Handle template selection
  const handleTemplateChange = (selectedOption) => {
    const templateId = selectedOption ? selectedOption.value : "";
    setSelectedTemplate(templateId);
    setVariables({});
    setError("");
    setSelectedTemplateError("");

    const template = templates.find((t) => t.templateId === templateId);

    if (template) {
      let bodyContent = template.templateBody;
      bodyContent = bodyContent.replace(/\\n/g, "<br />");
      console.log(bodyContent);
      setMessageContent(bodyContent);
    } else {
      setMessageContent("");
    }
  };

  // Handle variable input changes
  // const handleVariableChange = (varNumber, value) => {
  //   setVariables((prev) => ({
  //     ...prev,
  //     [varNumber]: value,
  //   }));
  // };

  // Update message content whenever variables change
  useEffect(() => {
    if (selectedTemplate) {
      const template = templates.find((t) => t.id === selectedTemplate);
      if (template) {
        setMessageContent(template.content);
        setMessageContent(replaceVariables(template.content));
      }
    }
  }, [selectedTemplate]);

  // Update selected tab when selectedChannel changes
  // useEffect(() => {
  //   setSelectedTab(selectedChannel);
  // }, [selectedChannel]);


  // fetch templatse options 
  useEffect(() => {
    fetchTempltes();
    fetchWhatsappMessageHistory(); 
  }, []);

  


  const fetchTempltes = async () => {
    try {
      const response = await whatsapp_list({ userId });

      if (response.data.code === 200) {
        setTemplates(response.data.data?.templateData || []);
      }else { 

      }

    } catch (e) {
      
    }
  }

  const selectedTemplateData = templates.find((t) => t.templateId === selectedTemplate);

  const sendWhatsappMessage = async () => {

    if (!selectedTemplate) {
      setSelectedTemplateError("Please select a template");
      return;
    }

    try {
      const response = await whatsapp_send({
        userId,
        templateId: selectedTemplate,
        mobileNo: mobileNo.mobileNo
      });
      if (response.data.code === 200) {

        onClose();
      } else {
        setError(response?.data?.message || "Something went wrong");
      }
    } catch (error) {
      setError("Something went wrong");
    }
  };

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-black/50 tw-flex tw-items-center tw-justify-center tw-z-50 tw-p-4">
      <div className="tw-bg-white tw-rounded-lg tw-w-full tw-max-w-5xl tw-max-h-[90vh] tw-overflow-auto">
        {/* Header */}
        <div className="tw-sticky tw-top-0 tw-z-10 tw-bg-white tw-px-6 tw-py-4 tw-border-b">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-relative">
            <h2 className="tw-text-3xl tw-text-center tw-font-semibold tw-text-gray-500">
            Send Program Communication to {memberName}
            </h2>
            <p className="tw-text-xs tw-text-gray-500">The mobile number is updated for communication only, not on the Member profile.</p>
            <button
              onClick={onClose}
              className="tw-text-gray-500 tw-absolute tw-top-0 tw-right-0 hover:tw-text-gray-700 tw-border-0 tw-bg-gray-200 hover:tw-bg-gray-400 tw-rounded-full tw-px-2 tw-py-1"
            >
              ✕
            </button>
          </div>
        </div>

        <div className="tw-px-6">
          <div className="tw-flex tw-gap-6 tw-flex-col md:tw-flex-row">
            {/* Message Preview */}
            <div className="tw-w-1/2 tw-h-full tw-space-y-6">
              {/* Mobile Number Section - Fixed Height */}
              <div className="tw-h-[42px]">
                <div className="tw-w-full tw-flex tw-flex-row tw-items-start">
                  <h3 className="user-card-subtitle tw-w-1/3">Mobile No </h3>

                  <div className="tw-w-2/3">
                    {/* {mobileNoChange ? (
              <div className="tw-flex tw-items-start  tw-gap-2">
                <div className="tw-flex-1">
                  <input
                    type="text"
                    placeholder="Mobile No"
                    name="mobile"
                    className="form-control service-action editable-mobile-no tw-w-full"
                    value={newPhoneNumber}
                    onChange={(e) => setNewPhoneNumber(e.target.value)}
                  />
                  <p className="tw-text-[10px] tw-text-red-500"></p>
                </div>
                <div className="tw-flex tw-gap-2 tw-mt-1">
                  <span
                    onClick={() => setMobileNoChange(false)}
                    className="tw-cursor-pointer"
                  >
                    <i className="bi bi-check-circle-fill"></i>
                  </span>
                  <span
                    onClick={() => setMobileNoChange(false)}
                    className="tw-cursor-pointer"
                  >
                    <i className="bi bi-x-circle-fill"></i>
                  </span>
                </div>
              </div>
            ) : (
              <div className="tw-flex tw-items-center">
                <span className="user-card-subtitle">: {mobileNo}</span>
                <span
                  onClick={() => setMobileNoChange(true)}
                  className="tw-cursor-pointer tw-ml-2"
                >
                  <i className="bi bi-pencil-square"></i>
                </span>
              </div>
            )} */}
                    {/* {mobileNoJSX} */}


                    <div>
                      {
                        mobileNoDisplayMode === "display" ?
                        
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <span className="tw-text-gray-700">: {mobileNo.mobileNo}</span>
                            <span
                              className="tw-cursor-pointer tw-text-black"
                              onClick={e => {
                                setMobileNoDisplayMode("edit");
                                setMobileNo({
                                  ...mobileNo,
                                  copiedMobileNo: mobileNo.mobileNo
                                })
                              }}
                            >
                              <i className="bi bi-pencil-square"></i>
                            </span>
                          </div> :
                          <div>
                          <div className="tw-flex tw-flex-row tw-items-start tw-gap-2">
                            <div className="tw-flex tw-flex-col">
                              <input
                                type="text"
                                placeholder="Mobile No"
                                name="mobile"
                                className="form-control service-action editable-mobile-no tw-w-full"
                                value={mobileNo.mobileNo}
                                onChange={(e) => setMobileNo({ ...mobileNo, mobileNo: e.target.value })}
                              />
                              {/* <p className="tw-text-xs tw-text-red-500 tw-mt-1">
                              </p> */}
                            </div>

                            <div
                              className="tw-cursor-pointer tw-text-black tw-mt-1"
                              onClick={() => {
                                setMobileNoDisplayMode("display")
                              }}
                            >
                              <i className="bi bi-check-circle-fill"></i>
                            </div>

                            <div
                              className="tw-cursor-pointer tw-text-black tw-mt-1"
                              onClick={() => {
                                setMobileNoDisplayMode("display")
                                setMobileNo({
                                  ...mobileNo,
                                  mobileNo: mobileNo.copiedMobileNo
                                })
                              }}
                            >
                              <i className="bi bi-x-circle-fill"></i>
                            </div>
                          </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
                      <p className="tw-text-xs tw-text-gray-500">
                        <strong>Note:</strong> The mobile number can be updated for communication only, not on the Member profile.
                        </p>
              </div>

              {/* Template Selection - Fixed Position */}
              <div className="tw-w-full">
                <Select
                  value={templateOptions.find(option => option.value === selectedTemplate)}
                  onChange={handleTemplateChange}
                  options={templateOptions}
                  styles={customStyles}
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  placeholder="Search Template"
                  noOptionsMessage={() => "No templates found"}
                />
                <p style={{ color: "red" }}>{selectedTemplateError}</p>
              </div>
              <div className="tw-w-full md:tw-w-[360px] tw-mx-auto">
              <div className="tw-relative tw-h-[600px]">
                {/* Phone Background Image */}
                <div className="tw-w-full tw-h-full tw-bg-[url('https://appcdn.goqii.com/storeimg/49871_1729598479.png')] tw-bg-contain tw-bg-no-repeat tw-bg-center"></div>

                {/* Message Content Overlay */}
                <div className="tw-absolute tw-top-[85px] tw-left-[25px] tw-right-[25px] tw-bottom-[85px] tw-overflow-y-auto [&::-webkit-scrollbar]:tw-hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
                  <div className="tw-text-xs tw-border tw-border-red-500 tw-text-gray-700 tw-bg-white tw-rounded-lg tw-px-2 tw-w-1/3 tw-text-center tw-mx-auto tw-mb-1 tw-font-semibold">
                    Today
                  </div>
                      {selectedTemplateData && (
                        <div className="tw-bg-white tw-rounded-lg tw-p-3 tw-shadow-sm">
                        <div className="tw-text-gray-600 tw-text-sm tw-mb-3">


                          { 
                            selectedTemplateData.mediaUrl ? 
                            <div> 
                                {selectedTemplateData.type === "Image" ? 
                                  <img 
                                    src={`${selectedTemplateData.mediaUrl}`} 
                                    // alt={template.templateName}
                                    className="tw-w-full tw-h-full tw-object-cover tw-mb-2"
                                  />
                                : ( 
                                  selectedTemplateData.type === "Video" ?
                                  <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
                                    <video className="tw-w-full tw-h-full tw-mb-2 tw-text-gray-400" controls>
                                      <source
                                        src={`${selectedTemplateData.mediaUrl}`}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>:                                   
                                  (
                                    selectedTemplateData.type === "Document" ?
                                    <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
                                      <embed
                                        src={`${selectedTemplateData.mediaUrl}`}
                                        type="application/pdf"
                                        className="tw-w-full tw-h-full tw-mb-2 tw-text-gray-400"
                                      />
                                    </div> : null
                                  )
                                  
                              )}  
                            </div> : null
                          }

                          

                          <p className="tw-text-wrap" dangerouslySetInnerHTML={{ __html:  messageContent }}></p>

                       </div>
                      {selectedTemplateData?.buttons && (
                        <div className="tw-flex tw-flex-col tw-gap-2">
                          {selectedTemplateData?.buttons?.map((button, index) => (
                            <button
                              key={index}
                              className={`tw-text-sm tw-py-1 tw-px-3 tw-rounded tw-text-center tw-border-0 ${button.type === "url"
                                  ? "tw-bg-blue-500 tw-text-white"
                                  : "tw-bg-gray-100 tw-text-gray-700"
                                }`}
                            >
                              {button.text}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            </div>

            <div className="vr"/>
            {/* Phone Preview */}
            <div className="tw-w-1/2 tw-h-full">
                  <MessageHistory  messageHistoryData={messageHistoryData}/>
            </div>
          </div>


          {/* Send Button */}
          <div className="tw-mt-6 tw-sticky tw-bottom-0 tw-bg-white tw-px-6 tw-py-4 tw-border-b tw-flex tw-justify-center">
            <div className="text-center">
              <div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </div>
              <button
                className="add-new-program-btn "
                onClick={() => {
                  sendWhatsappMessage();
                }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default CommunicationModal;
