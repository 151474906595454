import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

const DEFAULT_STATUS = 'All';
const DEFAULT_CATEGORY = 'All';
const DEFAULT_LANGUAGE = 'All';
const DEFAULT_SEARCH_QUERY = '';

const StatusBadge = ({ status }) => {
  const badgeStyle = {
    // padding: '4px 12px',
    borderRadius: '12px',
    fontSize: '12px',
    fontWeight: '500',
    textTransform: 'capitalize',
  };

  const statusStyles = {
    approved: {
      ...badgeStyle,
      color: '#73a165',
    },
    rejected: {
      ...badgeStyle,
      color: '#fd5a56',
    },
    pending: {
      ...badgeStyle,
      color: '#ffc107',
    }
  };

  const appliedStyle = status.toLowerCase() === 'approved' ? statusStyles.approved : status.toLowerCase() === 'rejected' ? statusStyles.rejected : statusStyles.pending;

  return <span style={appliedStyle}>{status}</span>;
};

function WADeleted({templates }) {
  const [searchQuery, setSearchQuery] = useState(DEFAULT_SEARCH_QUERY);
  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY);
  const [selectedStatus, setSelectedStatus] = useState(DEFAULT_STATUS);
  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_LANGUAGE);

  const columns = [
    { name: 'Template Name', selector: row => row.templateName, sortable: true },
    { name: 'Status', selector: row => row.status, sortable: true, cell: row => <StatusBadge status={row.status} /> },
    { name: 'Category', selector: row => row.category, sortable: true },
    { name: 'Language', selector: row => row.language, sortable: true },
    { name: 'Created By', selector: row => row.createdBy, sortable: true },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#D4E6EF',
        fontWeight: '500',
        fontSize: '14px',
        whiteSpace: 'normal',
      },
    },
    rows: {
      style: {
        whiteSpace: 'normal',
      },
    },
    cells: {
      style: {
        whiteSpace: 'normal',
      },
    },
  };

  const handleClear = () => {
    setSearchQuery(DEFAULT_SEARCH_QUERY);
    setSelectedCategory(DEFAULT_CATEGORY);
    setSelectedStatus(DEFAULT_STATUS);
    setSelectedLanguage(DEFAULT_LANGUAGE);
  };

  const filteredData = templates.templateData?.filter(item => {
    const matchesSearchQuery = item.templateName.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === DEFAULT_CATEGORY || item.category === selectedCategory;
    const matchesStatus = selectedStatus === DEFAULT_STATUS || item.status === selectedStatus;
    const matchesLanguage = selectedLanguage === DEFAULT_LANGUAGE || item.language === selectedLanguage;

    return matchesSearchQuery && matchesCategory && matchesStatus && matchesLanguage;
  });

  return (
    <div className="container-fluid">
      <div className="row mb-3 align-items-center">
        <div className="col-md d-flex px-1">
          <div className="form-group" style={{ width: '100%' }}>
            <input
              type="text"
              placeholder="Search By Template Name"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              className="form-control input-field-ui ps-5"
            />
            <img
              src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
              className="input-icon-seacrh"
              alt="search-icon"
            />
          </div>
        </div>

        <div className="col-md-2 px-1">
          <select
            value={selectedStatus}
            onChange={e => setSelectedStatus(e.target.value)}
            className="form-select input-field-ui"
          >
            <option value={DEFAULT_STATUS}>--Select Status--</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
            <option value="Approval Pending">Pending</option>
          </select>
        </div>

        <div className="col-md-2 px-1">
          <select
            value={selectedCategory}
            onChange={e => setSelectedCategory(e.target.value)}
            className="form-select input-field-ui"
          >
            <option value={DEFAULT_CATEGORY}>--Select Category--</option>
            <option value="Marketing">Marketing</option>
            <option value="Utility">Utility</option>
            <option value="authentication">Authentication</option>
          </select>
        </div>

        <div className="col-md-2 px-1">
          <select
            value={selectedLanguage}
            onChange={e => setSelectedLanguage(e.target.value)}
            className="form-select input-field-ui"
          >
            <option value={DEFAULT_LANGUAGE}>--Select Language--</option>
            <option value="English">English</option>
            <option value="Arabic">Arabic</option>
          </select>
        </div>

        <div className="col-md-auto px-1">
          <button onClick={handleClear} className="btn btn-primary clear-search-btn">Clear</button>
        </div>

      </div>

      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        paginationPerPage={10}
        striped
        responsive
        noHeader
        customStyles={customStyles}
      />
    </div>
  );
}

export default WADeleted;
