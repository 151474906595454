import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DynamicUrlInfo from "./DynamicUrlInfo";

const RanderSection = ({
  section,
  index,
  sections,
  setSections,
  collapsedSections,
  setCollapsedSections,
  websiteUrls,
  setWebsiteUrls,
  quickReplies,
  setQuickReplies,
  phoneNo,
  setPhoneNo,
  urlType,
  setUrlType,
  activeTab,
  countryCode,
  setCountryCode,
  buttonType,
}) => {
  const isChecked = collapsedSections[section.id];
  const [showInputs, setShowInputs] = useState(false);
  const [availableSlots, setAvailableSlots] = useState(5);
console.log("quickReplies",typeof quickReplies);
  const countryCodes = [
    { code: '966', country: 'Saudi Arabia', flagCode: 'sa' },
    { code: '91', country: 'India', flagCode: 'in' },
    { code: '1', country: 'United States', flagCode: 'us' },
    { code: '44', country: 'United Kingdom', flagCode: 'gb' },
    { code: '971', country: 'United Arab Emirates', flagCode: 'ae' },
    { code: '965', country: 'Kuwait', flagCode: 'kw' },
    { code: '973', country: 'Bahrain', flagCode: 'bh' },
    { code: '974', country: 'Qatar', flagCode: 'qa' },
    { code: '968', country: 'Oman', flagCode: 'om' },
    { code: '962', country: 'Jordan', flagCode: 'jo' },
    { code: '20', country: 'Egypt', flagCode: 'eg' },
    { code: '90', country: 'Turkey', flagCode: 'tr' },
    { code: '92', country: 'Pakistan', flagCode: 'pk' },
    { code: '880', country: 'Bangladesh', flagCode: 'bd' },
    { code: '94', country: 'Sri Lanka', flagCode: 'lk' },
    { code: '63', country: 'Philippines', flagCode: 'ph' },
    { code: '62', country: 'Indonesia', flagCode: 'id' },
    { code: '60', country: 'Malaysia', flagCode: 'my' },
    { code: '65', country: 'Singapore', flagCode: 'sg' },
    { code: '66', country: 'Thailand', flagCode: 'th' }
  ].sort((a, b) => a.country.localeCompare(b.country));

  const FlagImage = ({ flagCode }) => (
    <img
      src={`https://flagcdn.com/w20/${flagCode.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${flagCode.toLowerCase()}.png 2x`}
      width="20"
      height="15"
      alt=""
      className="tw-inline-block tw-mr-2 tw-align-middle"
    />
  );

  useEffect(() => {
    const usedSlots =
      websiteUrls.length + (phoneNo.phone ? 1 : 0) + quickReplies.length;
    setAvailableSlots(10 - usedSlots);
  }, [websiteUrls, phoneNo, quickReplies]);

  // const toggleSection = (sectionId) => {
  //   setCollapsedSections((prev) => {
  //     const newState = { ...prev, [sectionId]: !prev[sectionId] };

  //     // If unchecking, clear the inputs for this section only
  //     if (!newState[sectionId]) {
  //       switch (sectionId) {
  //         case "website":
  //           setWebsiteUrls([{ url: "", title: "", url:"URL" }]);
  //           break;
  //         case "quickReplies":
  //           setQuickReplies([""]);
  //           break;
  //         case "phone":
  //           setPhoneNo({ phone: "", title: "", url:"PHONE_NUMBER"});
  //           break;
  //       }
  //       setShowInputs(false);
  //     }

  //     return newState;
  //   });
  // };

  const toggleSection = (sectionId) => {
    setCollapsedSections((prev) => {
      const newState = { ...prev };

      if (sectionId === "quickReplies") {
        // If toggling quick replies
        if (!prev[sectionId]) {
          // If enabling quick replies, disable URL and phone
          newState.website = false;
          newState.phone = false;
          newState.quickReplies = true;
          
          // Clear URL and phone data
          setWebsiteUrls([]);
          setPhoneNo({ phone: "", title: "", type: "PHONE_NUMBER" });
          
          // Initialize quick replies if empty
          if (quickReplies.length === 0) {
            setQuickReplies([""]);
          }
        } else {
          // If disabling quick replies
          newState.website = true;
          newState.phone = true;
          newState.quickReplies = false;
          setQuickReplies([]);
        }
      } else {
        // If toggling URL or phone
        if (!prev[sectionId]) {
          // If enabling URL or phone, disable quick replies
          newState.quickReplies = false;
          setQuickReplies([]);
          
          // Enable the clicked section
          newState[sectionId] = true;
          
          // Initialize appropriate data structure
          if (sectionId === "website" && websiteUrls.length === 0) {
            setWebsiteUrls([{ url: "", title: "", type: "URL" }]);
          } else if (sectionId === "phone" && !phoneNo.phone) {
            setPhoneNo({ phone: "", title: "", type: "PHONE_NUMBER" });
          }
        } else {
          // If disabling URL or phone
          newState[sectionId] = false;
          if (sectionId === "website") {
            setWebsiteUrls([]);
          } else if (sectionId === "phone") {
            setPhoneNo({ phone: "", title: "", type: "PHONE_NUMBER" });
          }
        }
      }

      return newState;
    });
  };

  useEffect(() => {
    const isQuickRepliesActive = collapsedSections.quickReplies;
    const isUrlOrPhoneActive = collapsedSections.website || collapsedSections.phone;

    if (isQuickRepliesActive && isUrlOrPhoneActive) {
      setCollapsedSections(prev => ({
        ...prev,
        website: false,
        phone: false
      }));
      setWebsiteUrls([]);
      setPhoneNo({ phone: "", title: "", type: "PHONE_NUMBER" });
    }
  }, [collapsedSections]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = Number(e.dataTransfer.getData("text/plain"));
    const newSections = [...sections];
    const [reorderedItem] = newSections.splice(dragIndex, 1);
    newSections.splice(dropIndex, 0, reorderedItem);
    setSections(newSections);
  };

  const addWebsiteUrl = () => {
    if (websiteUrls.length < 2 && availableSlots > 0) {
      setWebsiteUrls([...websiteUrls, { url: "", title: "", type: "URL" }]);
    }
  };

  const removeWebsiteUrl = (index) => {
    const newUrls = websiteUrls.filter((_, i) => i !== index);
    setWebsiteUrls(newUrls);
  };

  const addQuickReply = () => {
    if (availableSlots !== 5) {
      setQuickReplies([...quickReplies, ""]);
    }
  };

  const removeQuickReply = (index) => {
    const newReplies = quickReplies.filter((_, i) => i !== index);
    setQuickReplies(newReplies);
  };

  const handleAddClick = () => {
    setShowInputs(true);
    if (section.id === "website" && websiteUrls.length === 0) {
      addWebsiteUrl();
    } else if (section.id === "quickReplies" && quickReplies.length === 0) {
      addQuickReply();
    } else if (section.id === "phone" && !phoneNo.phone) {
      setPhoneNo([{ phone: "", title: "", type: "PHONE_NUMBER" }]);
    }
  };


  const commonHeader = (
    <div
      className="mb-2"
      //   onClick={() => toggleSection(section.id)}
      style={{ cursor: "pointer" }}
    >
      <div className="tw-flex tw-flex-row tw-justify-start gap-2 tw-items-center">
        <i className="bi bi-grip-vertical tw-cursor-pointer tw-mt-1"></i>
        <input
          className="form-check-input form-check-input-ui"
          type="checkbox"
          name={`Add${section.id}`}
          id={`flexCheck${section.id}`}
          // checked={section.id === 'quickReplies' || (section.id === 'phone'  && section.id === 'website') ? isChecked : ''}
          checked={isChecked}
          onChange={() => toggleSection(section.id)}
        />
        <label
          className="form-check-label mt-1"
          htmlFor={`flexCheck${section.id}`}
        >
          {section.title}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top" placement="top">
                {section.tooltip}
              </Tooltip>
            }
          >
            <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
          </OverlayTrigger>
        </label>
        <i className={`bi bi-chevron-${isChecked ? "down" : "up"} ms-auto`}></i>
      </div>
    </div>
  );

  const contentClass = `row align-items-center  ${isChecked ? "" : "d-none"}`;

  const renderWebsiteSection = () => (
    <div className={contentClass}>
      {!showInputs && (
        <>
          {websiteUrls.map((url, urlIndex) => (
            <React.Fragment key={urlIndex}>
              <div className="col-3">
                <select
                  className="form-select input-field-ui"
                  id=""
                  name="urlType"
                  aria-label="Select url"
                  value={urlType}
                  onChange={(e) => {
                    setUrlType(e.target.value);
                  }}
                >
                  <option title="Insert the URL for a webpage where users will be directed when they click this button. Make sure the URL is correct and accessible." value="static">Static</option>
                  <option title="Use a dynamic URL to personalize the link based on user information. This is useful for directing users to personalized landing pages or profile-related URLs." value="dynamic">Dynamic</option>
                </select>
              </div>
              <div className="col-8 px-0">
                <input
                  type="text"
                  className="form-control input-field-ui"
                  placeholder="Enter URL, (Eg: https://example.com/test)"
                  value={url.url}
                  onChange={(e) => {
                    const newUrls = [...websiteUrls];
                    newUrls[urlIndex].url = e.target.value;
                    setWebsiteUrls(newUrls);
                  }}
                />
              </div>
              <div className="col-1">
                <button
                  className="btn tw-text-orange-600"
                  onClick={() => removeWebsiteUrl(urlIndex)}
                >
                  <i className="bx bxs-x-circle"></i>
                </button>
              </div>
              <div className="col-11 pe-0 mt-2 mb-2">
                <div className="position-relative d-flex align-items-center">
                  <input
                    type="text"
                    className={`form-control ${ activeTab === "arabic" ? "tw-text-end tw-pr-16 tw-py-2 tw-rounded-xl tw-w-full tw-border tw-m-1" : "input-field-ui"}`}
                    placeholder="Enter text for the button"
                    value={url.title}
                    onChange={(e) => {
                      const newUrls = [...websiteUrls];
                      newUrls[urlIndex].title = e.target.value.slice(0, 25);
                      setWebsiteUrls(newUrls);
                    }}
                  />
                  <div className="limited-inputs">
                    {" "}
                    {url.title?.length || 0}/25
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
          {urlType === "dynamic" && (
            <div className="tw-px-3">
              <DynamicUrlInfo />
            </div>
          )}
          {websiteUrls.length < 2 && availableSlots > 0 && (
            <div className="col-12 mt-2">
              <a
                type="button"
                className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                onClick={addWebsiteUrl}
              >
                <i className="bx bxs-plus-circle"></i> Add another website URL
                <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top" placement="top">
                Click to add another website button. You can add up to 10 total buttons across all types.
              </Tooltip>
            }
          >
            <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
          </OverlayTrigger>
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );

  const renderQuickRepliesSection = () => (
    <div className={contentClass}>
      {!showInputs && (
        <>
          {quickReplies && quickReplies?.map((reply, replyIndex) => (
            <React.Fragment key={replyIndex}>
              <div className="col-11 pe-0 mb-2">
                <div className="position-relative d-flex align-items-center">
                  <input
                    type="text"
                    className={`form-control ${ activeTab === "arabic" ? "tw-text-end tw-pr-16 tw-py-2 tw-rounded-xl tw-w-full tw-border tw-m-1" : "input-field-ui"}`}
                    placeholder="Enter text for the button"
                    value={reply.id}
                    onChange={(e) => {
                      const newReplies = [...quickReplies];
                      newReplies[replyIndex] = e.target.value.slice(0, 25);
                      setQuickReplies(newReplies);
                    }}
                  />
                  <div className="limited-inputs"> {reply?.length || 0}/25</div>
                </div>
              </div>
              <div className="col-1 mb-2">
                <button
                  className="btn tw-text-orange-600"
                  onClick={() => removeQuickReply(replyIndex)}
                >
                  <i className="bx bxs-x-circle"></i>
                </button>
              </div>
            </React.Fragment>
          ))}
          {availableSlots !== 5 && (
            <div className="col-12 mt-2">
              <a
                type="button"
                className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                onClick={addQuickReply}
              >
                <i className="bx bxs-plus-circle"></i>
                <span>Add another Quick Reply</span>
                <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top" placement="top">
                Provide a pre-defined response option that users can select with a single tap. This helps automate and speed up user interactions.
              </Tooltip>
            }
          >
            <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
          </OverlayTrigger>
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );

  const renderPhoneSection = () => {
    const handlePhoneChange = (e) => {
      const value = e.target.value.replace(/\D/g, "").slice(0, 15);
      setPhoneNo({ ...phoneNo, phone: value });
    };
  
    const handleTitleChange = (e) => {
      const value = e.target.value.slice(0, 20);
      setPhoneNo({ ...phoneNo, title: value });
    };
  
    return (
      <div className={contentClass}>
        {!showInputs && (
          <>
            <div className="col-3">
            <div className="tw-relative">
      <select
        className="tw-form-select tw-w-full tw-pl-8 tw-pr-1 tw-py-2 tw-text-gray-700 tw-bg-white tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-border-blue-500"
        value={countryCode}
        onChange={(e) => setCountryCode(e.target.value)}
        aria-label="Select Country Code"
      >
        {countryCodes.map(({ code, country, flagCode }) => (
          <option
            key={code}
            value={code}
            className="tw-flex tw-items-center tw-py-2"
          >
            +{code} {country}
          </option>
        ))}
      </select>
      {/* Display current selected flag */}
      <div className="tw-absolute tw-left-2 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-pointer-events-none">
        <FlagImage 
          flagCode={countryCodes.find(c => c.code === countryCode)?.flagCode || 'sa'} 
        />
      </div>
    </div>
            </div>
            <div className="col-8 px-0">
              <div className="position-relative d-flex align-items-center">
                <input
                  type="tel"
                  pattern="[0-9]*"
                  className="form-control input-field-ui"
                  id="phone"
                  name="phone"
                  value={phoneNo.phone || ''}
                  onChange={handlePhoneChange}
                  placeholder="Enter Phone Number"
                  minLength={10}
                  maxLength={15}
                />
                <div className="limited-inputs">
                  {(phoneNo.phone || '').length}/15
                </div>
              </div>
            </div>
            <div className="col-11 pe-0 mt-2">
              <div className="position-relative d-flex align-items-center">
                <input
                  type="text"
                  className={`form-control ${activeTab === "arabic" ? "tw-text-end tw-pr-16 tw-py-2 tw-rounded-xl tw-w-full tw-border tw-m-1" : "input-field-ui"}`}
                  id="buttonText"
                  name="buttonText"
                  value={phoneNo.title || ''}
                  onChange={handleTitleChange}
                  placeholder="Enter text for the button"
                  maxLength={20}
                />
                <div className="limited-inputs">
                  {(phoneNo.title || '').length}/20
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  switch (section.id) {
    case "website":
      return (
        <>
        {buttonType === "url" && (
        <div
          className="col-12 mb-4"
          key={section.id}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
        >
          {commonHeader}
          {renderWebsiteSection()}
        </div>

        )}
        </>
      );
    case "quickReplies":
      return (
        <>
        {buttonType === "qreplies" && (
        <div
          className="col-12 mb-4"
          key={section.id}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
        >
          {commonHeader}
          {renderQuickRepliesSection()}
        </div>
        )}
        </>
      );
    case "phone":
      return (
        <>
        {buttonType === "url" && (
        <div
          className="col-12 mb-4"
          key={section.id}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
        >
          {commonHeader}
          {renderPhoneSection()}
        </div>
        )}
        </>
      );
    default:
      return null;
  }
};

export default RanderSection;
