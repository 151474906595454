import React, { useState } from 'react';
import ContentConfigurationList from './ContentConfigurationList/ContentConfigurationList';
import './ContentConfigurationList.css';


function ConfigurationListPage() {

  const [title, setTitle] = useState('Configure Service Communications');
 
return (
    <>
    <section className='ContentPrograms'>
        <ContentConfigurationList/>
        </section>
    </>
  );
}

export default ConfigurationListPage;
