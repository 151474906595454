import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { fetch_member_communication_data } from '../../../services/mtp/Service';
import { LoaderLoader } from '../../utils/CommonLibrary';

function MemberCommunicationsTable() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedChannel, setSelectedChannel] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [communicationData, setCommunicationData] = useState([]);
    const [communicationMessage, setCommunicationMessage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTooltip, setActiveTooltip] = useState(null);


useEffect(() => {
        fetchMemberCommunicationData();
},[]);


const fetchMemberCommunicationData = async () => {
    try {
        setIsLoading(true); // show loader
        let response = await fetch_member_communication_data();
        if(response.data.code === 200){
            setCommunicationData(response.data.data || []);
        }
        setIsLoading(false); // hide loader
    } catch (error) {
      console.error(error);
    }
};
    const columns = [
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Communication Message',
            selector: row =>  <p
            className='card-text'
            dangerouslySetInnerHTML={{ __html: row.communination }}
        ></p>,
            sortable: true,
        },
        {
            name: 'Sent Date',
            selector: row => row.DeliveredDate,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <div className='tw-relative tw-flex tw-items-center tw-gap-1'>
                    <span style={{ color: '#000000' }}>
                        {row.status}
                    </span>
                    {row.status === "Undelivered" && (
                        <div className="tw-relative tw-inline-block">
                            <div className="tw-group">
                                <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2"></i>
                                <div className="tw-invisible group-hover:tw-visible tw-absolute tw-z-[9999999999] tw-w-60 tw-h-60 tw-overflow-y-auto tw-left-3 tw-bottom-3 tw-bg-white tw-rounded-lg tw-border tw-shadow-lg tw-p-4">
                                    <div className="tw-relative">
                                        <p className="tw-text-sm tw-font-bold tw-text-red-600 tw-mb-2">
                                            ERROR: {row.errorCode}
                                        </p>
                                        <p className="tw-text-sm tw-text-gray-600 tw-mb-3">
                                            {row.description}
                                        </p>
                                        <div className="tw-flex tw-flex-col tw-gap-2">
                                            <span className='tw-text-sm'>
                                                <a
                                                    href={`https://www.twilio.com/docs/api/errors/${row.errorCode}`}
                                                    className="tw-text-blue-500 hover:tw-underline tw-text-sm tw-mr-1"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    View Error Details
                                                </a>
                                                about Error code <strong>{row.errorCode}</strong>
                                            </span>
                                            <span className='tw-text-sm'>
                                                Possible Solution: {row.solution}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ),
        },
        {
            name: 'Communication Channel',
            selector: row => row.communicationType,
            sortable: true,
        },
    ];
    const data = communicationData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
                minHeight: '60px',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedChannel('All');
        setSelectedStatus('All');
    };
    
    const filteredData = data.filter(item => {
        const matchesSearchQuery = 
            item.membershipNo.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||  // Matching memberId
            item.memberName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.communination.toLowerCase().includes(searchQuery.toLowerCase());
    
        const matchesChannel = selectedChannel === 'All' || item.communicationType === selectedChannel;
        const matchesStatus = selectedStatus === 'All' || item.status === selectedStatus;
    
        return matchesSearchQuery && matchesChannel && matchesStatus;
    });

    return (
        <>
            <LoaderLoader isLoading={isLoading} />
           
            <div className='container-fluid'>
                
                <div className='row mb-3'>
                    
                    <div className='col-md d-flex px-1'>
                    <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search By Member Name or Member ID"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="Sent">Sent</option>
                            <option value="Read">Read</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Undelivered">Undelivered</option>
                        </select>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedChannel}
                            onChange={e => setSelectedChannel(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Communication--</option>
                            <option value="Whatsapp">Whatsapp</option>
                            {/* <option value="SMS">SMS</option>
                            <option value="Push Notification">Push Notification</option>
                            <option value="Email">Email</option> */}
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            {communicationData === 0 ? <div>{communicationMessage}</div> :
            (<>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
            </>
            )}
        </>
    );
}

export default MemberCommunicationsTable;
