import React from "react";
import { Modal } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";

const SubmitModal = ({ submitModal, handleModalClose, message, handleClear }) => {
  const isSuccess = !message.toLowerCase().includes("error");

  return (
    <Modal show={submitModal} centered>
      <div className="tw-p-6 tw-text-center">
        {isSuccess && (
          <div className="tw-mb-4 tw-flex tw-justify-center">
            <FaRegCheckCircle className="tw-text-green-500" size={48} />
          </div>
        )}
        <h2 className="tw-text-xl tw-font-bold tw-mb-4 tw-text-gray-800">{message}</h2>
        <p className="tw-text-lg tw-font-semibold tw-mb-4 tw-text-gray-700">
          WhatsApp can take up to 24 hours to review <br/> (approve / reject) a template
        </p>
        <p className="tw-text-base tw-text-gray-600 tw-mb-6">
          In some cases, after template submission, the approval / rejection
          comes within the first 1 minute itself. However, if the template's
          status shows pending (yellow) even after 1 minute, then, it implies
          that WhatsApp might have sent it for manual review, which typically
          takes upto 24 hours.
        </p>
        <button
          onClick={handleModalClose}
          className="btn btn-primary add-new-program-btn"
        >
          Understood
        </button>
      </div>
    </Modal>
  );
};

export default SubmitModal;