import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { whatsAppCreateCampaign } from '../../../services/mtp/Service';
import { filterUserDataDownload } from '../../../services/whatsapp/Service';

const SetMessageLive = ({campaignName,setCampaignName,scheduling, dateTime,selectedTemplate,setSelectedTemplate, selectedFilteredList,setSelectedFilteredList,totalCountUserList,setTotalCountUserList,memberFilterSelectQuery,setMemberFilterSelectQuery,filterQueries,optOutNCount,setOptOutNCount,optOutYCount,setOptOutYCount}) => {

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const finalSubmitCampaign = () =>{
    const formData = new FormData();
    setErrorMessage('');
    // Validation logic
    if (!campaignName) {
      setErrorMessage('Campaign Name is required.');
      setSuccessMessage('');
      return;
    }

    if (!selectedFilteredList || selectedFilteredList.length === 0 || memberFilterSelectQuery==='') {
      setErrorMessage('Please select at least one filter');
      setSuccessMessage('');
      return;
    }

    if (!scheduling) {
      setErrorMessage('Scheduling is required.');
      setSuccessMessage('');
      return;
    }
    if (!dateTime) {
      setErrorMessage('Date and Time are required.');
      setSuccessMessage('');
      return;
    }
    if (!selectedTemplate) {
      setErrorMessage('A template must be selected.');
      setSuccessMessage('');
      return;
    }
    

    if(errorMessage === ""){

          const data = {
            'campaignName':campaignName,
            'scheduling': scheduling,
            'dateTime': dateTime,
            'selectedTemplate': selectedTemplate,
             'memberFilterSelectQuery': memberFilterSelectQuery, 
             'totalCountUserList':totalCountUserList,
             'filterQueries':filterQueries,
          }
          formData.append('data', JSON.stringify(data));
         whatsAppCreateCampaign(JSON.stringify(data))
          .then((response) => {
            if (response.data.code === 200) {
              setSuccessMessage(response.data.data.message);
              setErrorMessage('');
              setSelectedFilteredList([]);
              setTotalCountUserList('');
              setOptOutNCount('');
              setOptOutYCount('')
              setMemberFilterSelectQuery('');
              setSelectedTemplate(null);
              setCampaignName('');
              navigate('/notification-dashboard'); 
              
            }else{
              setErrorMessage(response.data.data.message);
              setSuccessMessage('');
            }
          })
      }

  }


  const filterDataDownload =async(userStatus)=>{
    try {

      let response = await filterUserDataDownload(memberFilterSelectQuery,userStatus);
      const blob = new Blob([response.data], { type: 'text/csv' });
          
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download','data.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
      
          window.URL.revokeObjectURL(downloadUrl);
      if(response.data.code == 200){
        console.log("download",response.data.data);
      }else{
        console.log("No record found");
      }
    }catch (error) {
      console.log(error); 
    }
  }
  
  const totalMembers = 22;
  const eligibleMembers = 20;
  const ineligibleMembers = 2;

    return (
      <div className="tw-p-4">
        <h3 className="tw-text-xl tw-font-bold tw-mb-4">Are you sure you are ready?</h3>
        {/* <p className="tw-text-gray-700 tw-mb-2">Number of members []</p> */}
        {/* <p className="tw-text-gray-600 tw-mb-4">
        Please make sure you have confirmed all the details before hitting the button. Once started, a campaign cannot be stopped midway and this might lead to unnecessary expenses at your end.
        </p> */}
       {/* {totalCountUserList > 0  && <p className="tw-text-gray-700 tw-mb-2">Number of Members {totalCountUserList}</p> }
       <p className="tw-text-gray-600 tw-mb-4">Please make sure you have confirmed all the details before hitting the button. Once started, a campaign cannot be stopped midway and this might lead to unnecessary expenses at your end.</p> */}
       {totalCountUserList > 0  && 
       <div className="tw-mb-4 tw-bg-gray-100 tw-p-4 tw-rounded-xl">
        <p className="tw-mb-2">
          The campaign will be set for {optOutNCount} members.&nbsp;
          <a href="#" className="tw-text-blue-500 hover:tw-text-blue-700 tw-underline" onClick={(e)=>filterDataDownload('N')}>
            [Download]
          </a>
        </p>
        
        <p className="tw-mb-2">
          A total of {totalCountUserList} members were filtered, however, {optOutYCount} members cannot receive this message.&nbsp;
          <a href="#" className="tw-text-blue-500 hover:tw-text-blue-700 tw-underline" onClick={(e)=>filterDataDownload('Y')}>
            [Download details on why]
          </a>
        </p>

        <button className="btn btn-success uplaod-file-btn">
          More Information on Message Delivery
        </button>
      </div>
      }
      <p className="tw-text-gray-600 tw-mb-6">
        Please make sure you have confirmed all the details before hitting the button. 
        Once started, a campaign cannot be stopped midway and this might lead to unnecessary expenses at your end.
      </p>
       
       {errorMessage && (
        <p className="tw-text-red-600 tw-mb-4">{errorMessage}</p>
      )}
      {successMessage && (
        <p className="tw-text-green-600 tw-mb-4">{successMessage}</p>
      )}

        <div className="tw-flex tw-space-x-4">
          <button className="btn btn-success uplaod-file-btn" onClick={finalSubmitCampaign}>
            Set live
          </button>
          {/* <button className="tw-border tw-border-blue-600 tw-text-blue-600 tw-px-6 tw-py-2 tw-rounded">
            Save as Draft
          </button> */}
        </div>
      </div>
    );
  };

  export default SetMessageLive