import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import firebase from './firebase/firebase';
import { getDatabase, ref, onValue, child, get } from "firebase/database";
import './App.css';
import Protected from './Protected';

import LoginPage from './pages/LoginPage';
import Logout from './pages/Logout';
import ValidatePage from './pages/utils/ValidatePage';
import ProgramsPage from './pages/mtp/program/ProgramsPage';
import ConfigurationListPage from './pages/componenents/ConfigurationListPage';
import UploadMemberDetailsPage from './pages/rcm/UploadMemberDetailsPage';
import ReportsPage from './pages/rcm/ReportsPage';
import ConfigurationPage from './pages/mtp/configurationpage/ConfigurationPage';
import UserManagementPage from './pages/mtp/usermanagement/UserManagementPage';
import MemberCommunicationsPage from './pages/mtp/membercommunication/MemberCommunicationsPage';
import CareNavigatorPage from './pages/CareNavigatorPage';
import ProgramPerformancePage from './pages/ProgramPerformancePage';
import CareNavigatorPerformancePage from './pages/CareNavigatorPerformancePage';
import ContentManagementPage from './pages/ContentManagementPage';
import ChallengesPage from './pages/ChallengesPage';
import ContentAddUserManagement from './pages/componenents/ContentAddUserManagement';
import AddProgramsPage from './pages/mtp/program/AddProgramsPage';
import ContentEditUserManagement from './pages/componenents/ContentEditUserManagement';
import CareAddNewUser from './pages/medengage/ContentUserManagement/CareAddNewUser';
import NoAccess from './pages/NoAccess';
import PathNotResolved from './pages/PathNotResolved';
import ContentServiceList from './pages/componenents/ContentPrograms/ContentServiceList';
import MemberEngagementPage from './pages/met/engagementpage/MemberEngagementPage';
import MemberDeEnrollmentPage from './pages/MemberDeEnrollmentPage';
import AddNewCareNavigator from './pages/componenents/ContentCareNavigator/AddNewCareNavigator';
import ContentMemberEngagementDetails from './pages/componenents/ContentMemberEngagement/ContentMemberEngagementDetails';
import ContentCallDispositionDetails from './pages/componenents/ContentMemberEngagement/ContentCallDispositionDetails';
import ContentCallHistory from './pages/componenents/ContentMemberEngagement/ContentCallHistory';
import ContentCallHistoryAgent from './pages/componenents/ContentMemberEngagement/ContentCallHistoryAgent';
import MemberEngagmentChallengePage from './pages/met/challenges/MemberEngagmentChallengePage';
import MemberConfigurationListPage from './pages/met/configuration/MemberConfigurationListPage';
import MemberConnectAgent from './pages/met/memberconnect/MemberConnectAgent';
import MemberCommunications from './pages/met/membercommunication/MemberCommunications';
//import MemberConnectAgent from './pages/MemberConnectAgent';
import ContentCareNavigatorPerformanceList from './pages/componenents/ContentCareNavigatorPerformance/ContentCareNavigatorPerformanceList';
import MemberTransferPage from './pages/MemberTransferPage';
import MemberManagement from './pages/met/usermanagement/UserManagementPage';
import ContentAddMemberManagement from './pages/met/components/ContentAddNewUser';
import ContentEditMemberManagement from './pages/met/components/ContentEditUserMangement';
import RCMUserManagement from './pages/rcm/usermanagement/UserManagementPage';
import RCMContentMemberManagement from './pages/rcm/components/ContentAddNewUser';
import RCMContentEditMemberManagement from './pages/rcm/components/ContentEditUserManagement';
// import ContentEditMemberManagement from './pages/met/components/ContentEditUserMangement';
import AddNewServices from './pages/componenents/ContentPrograms/AddNewServices';
import MedEngageUerManagement from './pages/medengage/usermanagement/UserManagementPage';
import MedEngageContentMemberManagement from './pages/medengage/components/ContentAddNewUser';
import MedEngageEditMemberManagement from './pages/medengage/components/ContentEditUserManagement';
import ConfigurationSettingsAgentCalls from './pages/met/configurationsettings/ConfigurationSettingsAgentCalls';
import ConfigurationSettingsCareNavigatorCalls from './pages/met/configurationsettings/ConfigurationSettingsCareNavigatorCalls';
import DashobardGraphs from './pages/DashboardGraphs';
import CNGraphs from './pages/CNGraphs';
import CareNavigatorCommunications from './pages/medengage/CareNavigatorCommunications/CareNavigatorCommunications';
import MemberProgress from './pages/medengage/MemberProgress/MemberProgress';

import CareNavigatorReports from './pages/CareNavigatorReports';
import SettingDispositionAgent from './pages/met/components/SettingDispositionAgent';
import MemberRegisterLaterPage from './pages/met/registerLater/MemberRegisterLaterPage';
//cn 
import Dashboard from './pages/cn/dashboard/Dashboard';
import Members from './pages/cn/members/Members';
import MemberProfile from './pages/MemberProfile/MemberProfile';
import { UserTabsProvider } from './pages/cn/components/common/UserTabsContext';
import Draggable from 'react-draggable';
import IncommingAudioCall from './pages/cn/components/draggableCall/IncommingAudioCall';
import CoachCallAudio from './pages/cn/components/draggableCall/CoachCallAudio';
import { decruptCipherString } from "./pages/utils/CommonLibrary";
import RCMInsight from './pages/Insight/components/InsightHome/RCMInsight';
import EndToEndDaily from './pages/Insight/components/InsightHome/EndToEndDaily';
import InsightAddNewUser from './pages/Insight/components/InsightAddNewUser/InsightAddNewUser';
import InsightUserManagementDetails from './pages/Insight/components/InsightUserManagementDetails/InsightUserManagementDetails';
import InsightEditUser from './pages/Insight/components/InsightEditUser/InsightEditUser';

import HomeScreen from './pages/RewardCentral/HomeScreen';
import LoyaltyProgram from './pages/RewardCentral/LoyaltyProgram';
import RewardPoints from './pages/RewardCentral/RewardPoints';
import CategoryMaster from './pages/RewardCentral/CategoryMaster';
import ProductMaster from './pages/RewardCentral/ProductMaster';
import ActivityManagement from './pages/RewardCentral/ActivityManagement';

import SupportTeamDashboard from './pages/SupportTeamDashboard';
import ApproversDashboard from './pages/ApproversDashboard';
import ObservationReportersDashboard from './pages/ObservationReportersDashboard';

import WhatsAppTemplates from './pages/WhatsAppTemplates/WhatsAppTemplates';
import CreateNewTemplate from './pages/WhatsAppTemplates/CreateNewTemplate';
import ContentAgentReports from './pages/met/Reports/ContentAgentReports';
import CNCalendar from './pages/medengage/cncalendar/CNCalendar';

import UploadMemberFile from './pages/RPA/UploadMemberFile';

import Notification from './pages/Notification-dashboard/Notification';
import CampaignDetails from './pages/Notification-dashboard/CampaignDetails';
import CreateCampaign from './pages/Notification-dashboard/CreateCampaign';

import CreateChallenges from './pages/CorpWellness360/CreateChallenges';
import CorporateChallenges from './pages/CorpWellness360/CorporateChallenges';
import CreateLeaderboard from './pages/CorpWellness360/CreateLeaderboard';
import ChallengeReport from './pages/CorpWellness360/ChallengeReport';
import ViewNewTemplate from './pages/WhatsAppTemplates/ViewNewTemplate';
import EditNewTemplate from './pages/WhatsAppTemplates/EditNewTemplate';
import { useSelector } from 'react-redux';
import CnLayout from './pages/componenents/Layout/CnLayout';
import ProgramsDraftPage from './pages/mtp/program/ProgramsDraftPage';
import RCMFileChecker from './pages/componenents/ContentRCMTeamDashboard/RCMFileChecker';
import RCMFileInsight from './pages/componenents/ContentRCMTeamDashboard/RCMFileInsight';
import RemoveUntouchMembers from './pages/componenents/ContentRCMTeamDashboard/RemoveUntouchMembers';

import Inbox from './pages/whatsapp/Inbox/Inbox';

import ContentMemberReenrollDetails from './pages/met/reenroll/ContentMemberReenrollDetails';
import RCMRemoveUntouchList from './pages/componenents/ContentRCMTeamDashboard/RCMRemoveUntouchList';
import ProritizePool from './pages/met/prioritizepool/ProritizePool';
import ProritizePoolList from './pages/met/prioritizepool/ProritizePoolList';
import CreateTemplateMet from './pages/met/whatspp/CreateTemplateMet';
import ContentUserManagementDetailsWhatapp from './pages/whatsapp/ContentUserManagementDetailsWhatapp';
import ContentAddNewUserWhatapp from './pages/whatsapp/ContentAddNewUserWhatapp';
import AnalyticsDashboard from './pages/whatsapp/AnalyticsDashboard';
import ContentEditUserWhatapp from './pages/whatsapp/ContentEditUserWhatapp';
  
function App() {

  const webex = useSelector(state => state.webex);
  console.log('Is Incomming Call: ', webex);
  let id = useCnID();

  // incomming call 
  const [shouldShowIncomming, setShouldShowIncomming] = useState(false);
  const [isAcceptedCall, setIsAcceptedCall] = useState(false);
  const [inConfNumber, setInconfNumber] = useState(null);
  const [cnID, setCnID] = useState(id);
  const [audio] = useState(new Audio('./ringtone.wav'));
  const [incommingMemberName, setIncommingMemberName] = useState("");
  const [incommingAgentName, setIncommingAgentName] = useState("");
  const [incommingMemberId, setIncommingMemberId] = useState("");

  // outgoing call 
  const [showCallBlock, setShowCallBlock] = useState(false);
  const [showDisposition, setShowDisposition] = useState(false);
  const [outgoingCallMemberInfo, setOutGoingCallMemberInfo] = useState({
    userId: "",
    memberName: "",
    mobile: ""
  });

  // open outoing call window
  function openOutGoingCallWindow(info) {
    if (showCallBlock) return;

    const { mobileNo: mobile, memberName, userId, extension } = info;
    setOutGoingCallMemberInfo({
      mobile, memberName, userId, extension
    });
    setShowCallBlock(true);
  }

  // close the call window
  function changeCallBlockDisplayStatus(status) {
    setShowCallBlock(status);
  }

  // trigger disposition 
  function triggerDisposition() {
    setShowDisposition(true);
  }



  useEffect(() => {

    const fetchData = async () => {

      const database = getDatabase(firebase);
      // just read values from this database 
      const dbRef = ref(database);
      const realtimeRef = ref(database, `carenavigator/${cnID}`);

      // write a onchange 
      onValue(realtimeRef, (snapshot) => {
        const data = snapshot.val();

        if (data) {
          // console.log(data);
          setInconfNumber(data.conference.confNumber);
          setIncommingMemberName(data.conference.memberName);
          setIncommingAgentName(data.conference.agentName);
          setShouldShowIncomming(true);
          setIncommingMemberId(data.conference.memberId);
          // playRingtone(); 
        } else {
          setShouldShowIncomming(false);
        }

      });

      get(child(dbRef, `carenavigator/${cnID}`)).then((snapshot) => {
        if (snapshot.exists()) {
          // console.log(snapshot.val());
        } else {
          // console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }

    if (cnID) fetchData();

  }, []);

  // const notification = new Notification("Member Added to Carenavigator", { body: "This is Test Notification", icon: "https://www.hindustantimes.com/static-content/1y/cricket-logos/players/virat-kohli.png" });



  return (
    <>

      {
        showCallBlock &&
        <Draggable bounds="body">
          <div className="box-draggable">
            <CoachCallAudio userId={outgoingCallMemberInfo.userId} memberName={outgoingCallMemberInfo.memberName} changeCallBlockDisplayStatus={changeCallBlockDisplayStatus} triggerDisposition={triggerDisposition} mobileNo={outgoingCallMemberInfo.mobile} extensionNo={outgoingCallMemberInfo.extension} />
          </div>
        </Draggable>
      }

      {
        webex.isIncommingCall &&
        <Draggable bounds="body">
          <div className="box-draggable">
            <IncommingAudioCall incommingConfNumber={inConfNumber}
              isAcceptedCall={isAcceptedCall} memberName={incommingMemberName} agentName={incommingAgentName}
              setIsAcceptedCall={setIsAcceptedCall} setShouldShowIncomming={setShouldShowIncomming} incommingMemberId={incommingMemberId}
              cnId={cnID}
              callerInfo ={webex.callerInfo}
            />

          </div>
        </Draggable>
      }


      <UserTabsProvider>

        <Routes>
          <Route path="/">

            {/* Starts Common */}
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/validate" element={<ValidatePage />} />
            <Route path="/no-access" element={<NoAccess />} title='Invalid Request' />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<PathNotResolved/>} />
            {/* Ends Common */}

            {/* Starts DataMigrate */}
            <Route path="/upload-member-details" element={<Protected Component={UploadMemberDetailsPage} title='Upload Member Details' pathNew = "upload-member-details"/>} />
            <Route path="/reports" element={<Protected Component={ReportsPage} title='Reports Overview' pathNew = "reports" /> } />
            <Route path="/approval-reports" element={<Protected Component={RCMFileChecker}  title='Upload File Checker' pathNew = "approval-reports" /> } />
            <Route path='/member-management-rcm' element={<Protected Component={RCMUserManagement} title='User Management' pathNew = "member-management-rcm"/>} />
            <Route path="/add-new-user-rcm" element={<Protected Component={RCMContentMemberManagement} title='Add New Member' pathNew = "add-new-user-rcm"/>} />
            <Route path='/edit-new-member-rcm/:id' element={<Protected Component={RCMContentEditMemberManagement} title='Edit New Member' pathNew = "edit-new-member-rcm"/>} />
            <Route path="/file-insight/:id?" element={<Protected Component={RCMFileInsight} title='Member Transfer' pathNew = "file-insight" />} />
            <Route path="/remove-untouch" element={<Protected Component={RemoveUntouchMembers} title='Remove Untouch Members' pathNew = "remove-untouch" />} />
            <Route path="/remove-untouch-list" element={<Protected Component={RCMRemoveUntouchList}  title='Upload File Checker' pathNew = "remove-untouch-list" /> } />
            {/* Ends DataMigrate */}

            {/* Starts MediCareManage */}
            <Route path="/programs" element={<Protected Component={ProgramsPage} title='Programs' pathNew = "programs" />} />
            <Route path="/draft-programs" element={<Protected Component={ProgramsDraftPage} title='Programs' />} />
            <Route path="/configuration-list" element={<Protected Component={ConfigurationListPage} title='Configuration List' pathNew = "configuration-list" />} />
            <Route path="/configuration" element={<Protected Component={ConfigurationPage} title='Configuration' pathNew = "configuration" />} />
            <Route path="/user-management" element={<Protected Component={UserManagementPage} title='User Management' pathNew = "user-management" />} />
            <Route path="/member-communications" element={<Protected Component={MemberCommunicationsPage} title='Member Communications' pathNew = "member-communications" />} />
            <Route path="/add-new-user" element={<Protected Component={ContentAddUserManagement} title='Add New user' pathNew = "add-new-user" />} />
            <Route path="/add-program" element={<Protected Component={AddProgramsPage} title='Add Programs' pathNew = "add-program" />} />
            <Route path="/add-service/:pathwayId" element={<Protected Component={ContentServiceList} title='Add Programs' pathNew = "add-service" />} />
            <Route path="/edit-new-user/:userId" element={<Protected Component={ContentEditUserManagement} title='Edit New user' pathNew = "edit-new-user" />} />
            <Route path="/add-new-service" element={<Protected Component={AddNewServices} title='Add New Service' pathNew = "add-new-service" />} />
            {/* Ends MediCareManage */}

            {/* Starts CareCoordinate */}
            <Route path="/care-navigator" element={<Protected Component={CareNavigatorPage} title='Care Navigator Management' pathNew = "care-navigator" />} />
            <Route path="/add-care-navigator/:id?" element={<Protected Component={AddNewCareNavigator} title='Add New Navigator Management' pathNew = "add-care-navigator" />} />
            <Route path="/edit-care-navigator/edit/:id?" element={<Protected Component={AddNewCareNavigator} title='Edit Navigator Management' pathNew = "edit-care-navigator" />} />
            <Route path="/program-performance" element={<Protected Component={ProgramPerformancePage} title='Population Compliance & Success' pathNew = "program-performance" />} />
            <Route path="/care-navigator-performance" element={<Protected Component={CareNavigatorPerformancePage} title='Care Navigator Performance' pathNew = "care-navigator-performance" />} />
            <Route path="/care-navigator-communications" element={<Protected Component={CareNavigatorCommunications} title='Care Navigator Communications' pathNew = "care-navigator-communications" />} />
            <Route path="/care-call-details" element={<Protected Component={ContentCareNavigatorPerformanceList} title='Care Call Details' pathNew = "care-call-details" />} />
            <Route path="/content-management" element={<Protected Component={ContentManagementPage} title='Content Management' pathNew = "content-management" />} />
            <Route path='/cn-graphs' element={<Protected Component={CNGraphs} title='cn Graphs' pathNew = "cn-graphs" />} />
            <Route path='/member-management-medengage' element={<Protected Component={MedEngageUerManagement} title='User Management' pathNew = "member-management-medengage" />} />
            <Route path="/add-new-user-medengage" element={<Protected Component={MedEngageContentMemberManagement} title='Add New Member' pathNew = "add-new-user-medengage" />} />
            <Route path='/edit-new-member-medengage/:id' element={<Protected Component={MedEngageEditMemberManagement} title='Edit New Member' pathNew = "edit-new-member-medengage" />} />
            <Route path="/member-de-enrollment" element={<Protected Component={MemberDeEnrollmentPage} title='Member De-Enrollment' pathNew = "member-de-enrollment" />} />
            <Route path="/member-transfer/:id?" element={<Protected Component={MemberTransferPage} title='Member Transfer' pathNew = "member-transfer" />} />
            <Route path='/care-navigator-reports' element={<Protected Component={CareNavigatorReports} title='Care Navigator Reports' pathNew = "care-navigator-reports" />} />
            <Route path='/member-data' element={<Protected Component={MemberProgress} title='Care Navigator Reports' pathNew = "member-data" />} />
            <Route path='/care-navigator-appointments' element={<Protected Component={CNCalendar} title='Care Navigator Appointments' pathNew = "care-navigator-appointments" />} />
            {/* Ends CareCoordinate */}
            

            {/* Starts MemberConnect */}
            <Route path="/member-insight" element={<Protected Component={ContentCallHistory} title='Member Engagement (Phase 1)' pathNew = "member-insight" />} />
            <Route path="/member-insight-agent" element={<Protected Component={ContentCallHistoryAgent} title='Member Engagement (Phase 1)' pathNew = "member-insight-agent" />} />
            <Route path="/challenges" element={<Protected Component={ChallengesPage} title='Challenges' pathNew = "challenges" />} />
            <Route path="/member-engagement1" element={<Protected Component={ContentMemberEngagementDetails} title='Member Engagement Mannual Phase 1' pathNew = "member-engagement1" />} />
            <Route path="/member-engagement" element={<Protected Component={MemberEngagementPage} title='Member Engagement' pathNew = "member-engagement" />} />
            <Route path="/member-configuration" element={<Protected Component={MemberConfigurationListPage} title='Member Engagement' pathNew = "member-configuration" />} />
            <Route path="/membercommunications" element={<Protected Component={MemberCommunications} title='Member Communications' pathNew = "membercommunications" />} />
            <Route path='/call-setting' element={<Protected Component={ConfigurationSettingsAgentCalls} title='Configuration Settings Agent Calls' pathNew = "call-setting" />} />
            <Route path="/member-challenges" element={<Protected Component={MemberEngagmentChallengePage} title='Member Challenges' pathNew = "member-challenges" />} />
            <Route path="/member-call-disposition" element={<Protected Component={ContentCallDispositionDetails} title='Member Call Disposition' pathNew = "member-call-disposition" />} />
            <Route path="/member-call-history" element={<Protected Component={ContentCallHistory} title='Member Call History' pathNew = "member-call-history" />} />
            <Route path='/member-management' element={<Protected Component={MemberManagement} title='Member Management' pathNew = "member-management" />} />
            <Route path="/add-new-member" element={<Protected Component={ContentAddMemberManagement} title='Add New Member' pathNew = "add-new-member" />} />
            <Route path='/edit-new-member/:userId' element={<Protected Component={ContentEditMemberManagement} title='Edit New Member' pathNew = "edit-new-member" />} />
            <Route path="/member-connect" element={<Protected Component={MemberConnectAgent} title='Member Connect Agent' pathNew = "member-connect" />} />
            <Route path='/call-settings-care-navigator-calls' element={<Protected Component={ConfigurationSettingsCareNavigatorCalls} title='Configuration Settings for Care Navigator Calls' pathNew = "call-settings-care-navigator-calls" />} />
            <Route path='/setting-disposition-agent' element={<Protected Component={SettingDispositionAgent} title='Setting Disposition Agent' pathNew = "setting-disposition-agent" />} />
            <Route path="/register-later" element={<Protected Component={MemberRegisterLaterPage} title='Member De-Enrollment' pathNew = "register-later" />} />
            <Route path='/agent-reports' element={<Protected Component={ContentAgentReports} title='Agent Reports' pathNew = "agent-reports" />} />
            <Route path='/reenroll-details' element={<Protected Component={ContentMemberReenrollDetails} title='Member Re-enroll' pathNew="reenroll-details" />} />
            <Route path='/proritize-pool' element={<Protected Component={ProritizePool} title='Proritize Pool' pathNew="proritize-pool" />} />
            <Route path="/proritize-pool-list" element={<Protected Component={ProritizePoolList}  title='Upload File Checker' pathNew = "proritize-pool-list" /> } />
            <Route path="/whatsapp-admin" element={<Protected Component={CreateTemplateMet}  title='Upload File Checker' pathNew = "whatsapp-admin" /> } />

            {/* Ends MemberConnect */}

            {/* Starts carenavigator */}
            <Route element={<CnLayout />}>
              <Route path="/dashboard" element={<Protected Component={Dashboard} title="cnDashboard" pathNew = "dashboard"  />} />
              <Route path="/members/:memberId" element={<Protected Component={Members} title="cnDashboard" pathNew = "members" childrenProps={{ openOutGoingCallWindow, showDisposition, setShowDisposition, showCallBlock }} />} />
            </Route>
              
            {/* Ends carenavigator */}

            {/* Starts 360 Profile */}
            {/* <Route path="/member-profile" element={<MemberProfile />} title="Member Profile" /> */}
            <Route path="/member-profile" element={<Protected Component={MemberProfile} title="cnDashboard" pathNew = "dashboard" />} />
            {/* End 360 Profile */}

            {/* Starts Insight */}
            <Route path="/end-to-end-dashboard" element={<Protected Component={RCMInsight} title="Insight Home" pathNew = "end-to-end-dashboard" />} />
            <Route path="/end-to-end-daily" element={<Protected Component={EndToEndDaily} title="Insight Home" pathNew = "end-to-end-daily" />} />
            <Route path='/member-management-end' element={<Protected Component={InsightUserManagementDetails} title='User Management' pathNew = "member-management-end" />} />
            <Route path="/add-new-user-end" element={<Protected Component={InsightAddNewUser} title='Add New Member' pathNew = "add-new-user-end" />} />
            <Route path='/edit-new-member-end/:id' element={<Protected Component={InsightEditUser} title='Edit New Member' pathNew = "edit-new-member-end" />} />
            {/* Ends Insight */}

            {/* Starts WhatsApp Templates */}
            <Route path="/whatsApp-templates" element={<Protected Component={WhatsAppTemplates} title="WhatsApp Templates" pathNew = "whatsApp-templates"  />} />
            <Route path="/create-new-template" element={<Protected Component={CreateNewTemplate} title="Create New Template" pathNew = "create-new-template"  />} />
            <Route path="/view-template/:id" element={<Protected Component={ViewNewTemplate} title="View Template" pathNew = "view-template"  />} />
            <Route path="/edit-template/:id" element={<Protected Component={EditNewTemplate} title="Edit Template" pathNew = "edit-template"  />} />
            
            {/* Starts WhatsApp Templates */}

            {/* Starts Reward Central System */}
            <Route path="/home-screen" element={<Protected Component={HomeScreen} title="Home Screen" />} />
            <Route path="/loyalty-program" element={<Protected Component={LoyaltyProgram} title="Loyalty Program" />} />
            <Route path="/reward-points" element={<Protected Component={RewardPoints} title="Reward Points" />} />
            <Route path="/category-master" element={<Protected Component={CategoryMaster} title="Category Master" />} />
            <Route path="/product-master" element={<Protected Component={ProductMaster} title="Product Master" />} />
            <Route path="/activity-management" element={<Protected Component={ActivityManagement} title="Activity Management" />} />
            {/* Ends Reward Central System */}

            {/* Starts Support Team Dashboard */}
            <Route path="/support-team-dashboard" element={<Protected Component={SupportTeamDashboard} title="Support Team Dashboard" />} />
            <Route path="/approvers-dashboard" element={<Protected Component={ApproversDashboard} title="Approvers Dashboard" />} />
            <Route path="/observation-reporters-dashboard" element={<Protected Component={ObservationReportersDashboard} title="Observation Reporters Dashboard" />} />
            {/* Starts Support Team Dashboard */}

            {/* Starts RPA Dashboard */}
            <Route path="/upload-member-file" element={<Protected Component={UploadMemberFile} title="Upload Member File" pathNew = "upload-member-file" />} />
            {/* End RPA Dashboard */}

            {/* Starts CorpWellness360 Dashboard */}
            <Route path="/create-challenges" element={<Protected Component={CreateChallenges} title="Create Challenges" />} />
            <Route path="/corporate-challenges" element={<Protected Component={CorporateChallenges} title="Corporate Challenges" />} />
            <Route path="/create-leaderboard" element={<Protected Component={CreateLeaderboard} title="Create Leaderboard" />} />
            <Route path="/challenge-report" element={<Protected Component={ChallengeReport} title="Challenge Report" />} />
            {/* End CorpWellness360 Dashboard */}


            <Route path='/dashobard-graphs' element={<Protected Component={DashobardGraphs} title='DashobardGraphs' />} />
            {/* <Route path="/member-connect" element={<MemberConnectAgent />} /> */}
            {/* <Route path="/care-add-user" element={<Protected Component={CareAddNewUser} title='User Management'/>} /> */}

            <Route path="/notification-dashboard" element={<Protected Component={Notification} title="Notification Dashboard"  pathNew = "notification-dashboard"  />} />
            <Route path="/campaign-details/:campaignId" element={<Protected Component={CampaignDetails} title="Campaign Details" pathNew = "campaign-details"  />} />
            <Route path="/create-campaign-whatsapp" element={<Protected Component={CreateCampaign} title="Create Campaign"  pathNew = "create-campaign-whatsapp"  />} />


           
            {/* WhatsAPP Chat Dashboard */}
            <Route path='/whatsapp-inbox' element={<Protected Component={Inbox} title='Inbox' pathNew = "whatsapp-inbox" />} />
            <Route path='/member-management-whatsapp' element={<Protected Component={ContentUserManagementDetailsWhatapp} title='User Management' pathNew = "member-management-whatsapp"/>} />
            <Route path="/add-new-user-whatsapp" element={<Protected Component={ContentAddNewUserWhatapp} title='Add New Member' pathNew = "add-new-user-whatsapp"/>} />
            <Route path='/edit-new-member-whatsapp/:id' element={<Protected Component={ContentEditUserWhatapp} title='Edit New Member' pathNew = "edit-new-member-whatsapp"/>} />

            <Route path='/analytics-dashboard-whatsapp' element={<Protected Component={AnalyticsDashboard} title='Edit New Member' pathNew = "analytics-dashboard-whatsapp"/>} />


            {/* END of WhatsAPP Chat Dashboard */}

          </Route>
        </Routes>
      </UserTabsProvider>
    </>
  );
}

export default App;


// custom hook for getting cnid  
export function useCnID() {
  const careNavigatorID = localStorage.getItem('cnId');
  return careNavigatorID;
}